import React from 'react';
import { browserVersion, isChrome, isEdge, isEdgeChromium, isFirefox } from "react-device-detect";
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { Store } from 'redux';
import RouteSelector from './components/specialRoutes/routeSelector';
import { changeHeight, changeWidth, navigationCollapse, navigationNotCollapse, setDarkMode } from './store/actions/window';
import { StoreDispatch } from './utils/types/storeTypes';

import * as Sentry from "@sentry/react";
import 'moment/locale/de';
import 'moment/locale/fr';
import './App.css';
import CustomConfigProvider from './components/app/customConfigProvider';
import LanguageProvider from './components/app/LanguageProvider';
import FullScreen from './components/common/general/fullscreen';
import CheckVersion from './components/specialRoutes/checkVersion';
import ExternalRouteSelector from './components/specialRoutes/externalRouteSelector';
import WrongBrowser from './components/specialRoutes/wrongBrowser';
import './styles/animations.css';
import './styles/antd-modifications.css';
import './styles/configurations.css';
import './styles/container.css';
import './styles/crm.css';
import './styles/fields.css';
import './styles/general.css';
import './styles/monthlyPlanning.css';
import './styles/navigations.css';
import './styles/planning.css';
import './styles/report.css';
import './styles/timeClock.css';
import './styles/variables.css';
import './styles/wrongBrowser.css';
import { documentsIconPack } from './utils/fontawesome/documentsPack';
import FontAwesome from './utils/fontawesome/fontawesome';
import { navbarPack } from './utils/fontawesome/navbarPack';
import { sunkhronosPack } from './utils/fontawesome/sunkhronosPack';

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route);

interface Props {
    store: Store,
    history: /*History<unknown>*/any;
}
interface State {
    correctBrowser: boolean;
}

const CHROME_VERSION = 80;
const FIREFOX_VERSION = 35;

FontAwesome.RegisterIconPack(navbarPack);
FontAwesome.RegisterIconPack(documentsIconPack);
FontAwesome.RegisterIconPack(sunkhronosPack);

/**
 * Base component
 */
class App extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            correctBrowser: true,
        };
    }

    componentDidMount() {
        this.browserCheck();
        //add a listener to be notified when the window is resized
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('storage', this.handleStorage);

        const collapsed: string | null = localStorage.getItem("collapsed");
        if (collapsed === "true") {
            (this.props.store.dispatch as StoreDispatch)(navigationCollapse());
        } else if (collapsed === "false") {
            (this.props.store.dispatch as StoreDispatch)(navigationNotCollapse());
        }
        const darkMode: string | null = localStorage.getItem("darkMode");
        if (darkMode === "true") {
            (this.props.store.dispatch as StoreDispatch)(setDarkMode(true));
        } else if (collapsed === "false") {
            (this.props.store.dispatch as StoreDispatch)(setDarkMode(false));
        }
    }

    componentWillUnmount() {
        //remove listener
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('storage', this.handleStorage);
    }


    /**
   * Called when the window is resized
   */
    handleResize = () => {
        //change the width and height value in redux store
        const windowWidth: number = typeof window !== "undefined" ? window.innerWidth : 0;
        const windowHeight: number = typeof window !== "undefined" ? window.innerHeight : 0;
        (this.props.store.dispatch as StoreDispatch)(changeHeight(windowHeight));
        (this.props.store.dispatch as StoreDispatch)(changeWidth(windowWidth));
    };

    handleStorage = () => window.location.reload();

    browserCheck = () => {
        if (isChrome && parseInt(browserVersion) <= CHROME_VERSION) {
            this.setState({ correctBrowser: false });
        } else if (isFirefox && parseInt(browserVersion) <= FIREFOX_VERSION) {
            this.setState({ correctBrowser: false });
        } else if (isEdge && !isEdgeChromium) {
            this.setState({ correctBrowser: false });
        } else {
            this.setState({ correctBrowser: true });
        }
    };

    displayBadBrowserError = () => {



        if (this.state.correctBrowser) {
            return (
                <Provider store={this.props.store}>
                    <Router history={this.props.history}>
                        <CustomConfigProvider>
                            <LanguageProvider>
                                <CheckVersion>
                                    <FullScreen>
                                        <Switch>
                                            <SentryRoute path="/:lang(en|fr|de)/app/" component={ExternalRouteSelector} />
                                            <SentryRoute component={RouteSelector} />
                                        </Switch>
                                    </FullScreen>
                                </CheckVersion>
                            </LanguageProvider>
                        </CustomConfigProvider>
                    </Router>
                </Provider>
            );
        }
        return (
            <WrongBrowser chrome={CHROME_VERSION} firefox={FIREFOX_VERSION} />
        );
    };

    render() {
        return this.displayBadBrowserError();
    }
}

export default App;
