import { DownloadOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, DatePicker, Image, Modal, Row, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from 'moment';
import React from 'react';
import isEqual from 'react-fast-compare';
import { SiMicrosoftexcel } from 'react-icons/si';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeTypesOfDayOff, loadDepartments } from '../../../store/actions/configurations';
import { changeUsers, toggleUsersLoading } from '../../../store/actions/teamManagement';
import { MOMENT_MONTH_FORMAT } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { CctSecurity } from '../../../utils/objects/cct/cctSecurity';
import { Company, DepartmentWithUpdate, Group, RouterProps, User, UserJobTMP } from '../../../utils/types/generalTypes';
import { NetworkAllHolidays, NetworkCctSecurityResponse, NetworkIncreasedHoursByDay } from '../../../utils/types/networkTypes';
import { AllHolidays, MonthlyHoursOfTheYear, MonthlyHoursOfTheYearArray, TypeOfDayOff } from '../../../utils/types/planningTypes';
import { CcntType, CctSecurityEventsByContractByMonth, CctSecurityResponse, CctSecurityType, IncreasedHoursByDay, InitialDaysOffCctSecurity, InitialHoursByContractByYear, InitialHoursCctSecurity, MonthlyReportType } from '../../../utils/types/reportTypes';
import { ApplicationState, ConfigurationsDispatchProps, StoreDispatch, TeamManagementDispatchProps } from '../../../utils/types/storeTypes';
import { alert, convertNetworkAllHolidaysToAllHolidays, convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay, convertNetworkCctSecurityResponseToCctSecurityResponse, showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import Card from '../../common/general/card';
import MultipleCcntPrintPage from '../ccnt/multipleCcntPrintPage';
import DrawerCctSecurity from './drawerReportContent';

interface IProps {
    users: User[];
    groups: Group[] | undefined;
    usersLoading: boolean;
    company?: Company;
    ccnt?: CcntType;
    cctSecurity?: CctSecurityType;
    width: number;
    currentUser: User | undefined;

    isSmartphone: boolean;
    height: number;
    departments: DepartmentWithUpdate;
    typesOfDayOff?: TypeOfDayOff[];
}

type Props = IProps & TeamManagementDispatchProps & RouterProps & ConfigurationsDispatchProps & IntlProps;

interface State {
    startMonth: Moment;
    endMonth: Moment;
    selectedUsers: number[];
    isLoading: number | undefined; //the id of the concerned user, or -1 if all
    usersToDisplay?: User[];
    searchedUsers?: User[];
    filteredGroups?: User[];
    selectGroups?: number[];
    users?: User[];
    selectedJob?: { userId: number, jobId: number | undefined }[];
    isCcntVisible: boolean;
    isMultipleCcntVisible: boolean;
    ccnt?: CcntType;
    cctSecurity?: CctSecurityType;
    multipleCcnt?: CcntType[];
    monthlyReport?: MonthlyReportType;
    monthlyHours?: MonthlyHoursOfTheYearArray;
    compressionProgression: number;
    canChangeGroup: boolean;
    modalDownloadMultipleVisible: boolean;
    manualForceRefreshTable: number;
    cctUser?: User;
    allHolidays?: AllHolidays[];
    cctSecurityEventsByContractByMonth?: CctSecurityEventsByContractByMonth[];
    increasedhoursByDayLoading: boolean;
    increasedhoursByDay?: IncreasedHoursByDay[];
    departmentIdSelected?: number;
    imageToOpen?: string;
}

class ReportTab extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            startMonth: moment().startOf("month"),
            endMonth: moment().endOf("month"),
            selectedUsers: [],
            isLoading: undefined,
            usersToDisplay: undefined,
            searchedUsers: undefined,
            filteredGroups: undefined,
            isCcntVisible: false,
            isMultipleCcntVisible: false,
            compressionProgression: 0.0,
            canChangeGroup: true,
            modalDownloadMultipleVisible: false,
            manualForceRefreshTable: 0,
            increasedhoursByDayLoading: false,
        };
    }

    componentDidMount() {
        this.props.loadDepartments!();
        // get all users
        this.props.toggleUsersLoading!(true);
        Network.getAllUsers().then(
            response => this.props.changeUsers!(response),
            () => alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the users' }), "warning"),
        );

        this.getAllHolidays();
        this.getAllIncreasedhoursByDay();
        this.getTypeOfDayOff();

        Network.getMonthlyHours(this.state.startMonth.year()).then(
            (response: MonthlyHoursOfTheYear) => {
                const monthlyHours: MonthlyHoursOfTheYearArray = {
                    id: response.id,
                    year: response.year,
                    hours: [
                        response.janHours ? response.janHours : 0,
                        response.febHours ? response.febHours : 0,
                        response.marHours ? response.marHours : 0,
                        response.aprHours ? response.aprHours : 0,
                        response.mayHours ? response.mayHours : 0,
                        response.junHours ? response.junHours : 0,
                        response.julHours ? response.julHours : 0,
                        response.augHours ? response.augHours : 0,
                        response.sepHours ? response.sepHours : 0,
                        response.octHours ? response.octHours : 0,
                        response.novHours ? response.novHours : 0,
                        response.decHours ? response.decHours : 0,
                    ]
                }
                this.setState({ monthlyHours: monthlyHours });
            },
            (error) => {
                if (error.message.includes(this.props.intl.formatMessage({ defaultMessage: 'No monthly hours' }))) {
                    this.setState({ monthlyHours: undefined });
                } else {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours by year' }), "error");
                }
            },
        );

        if (this.props.currentUser && this.props.currentUser.groupsAdmin && this.props.currentUser.groupsAdmin.length > 0) {
            this.changeGroup(this.props.currentUser.groupsAdmin.map(ga => ga.group_id));
            this.setState({ canChangeGroup: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        const { users } = this.state;
        if (this.props.currentUser && this.props.currentUser.groupsAdmin && this.props.currentUser.groupsAdmin.length > 0 && (!isEqual(prevProps.currentUser, this.props.currentUser) || !isEqual(prevProps.users, this.props.users))) {
            this.changeGroup(this.props.currentUser.groupsAdmin.map(ga => ga.group_id));
            this.setState({ canChangeGroup: false });
        }
        if (this.props.users && this.props.users.length > 0 && (!users || users.length === 0)) {
            this.updateListOfContracts();
        }

        if (!isEqual(prevProps.departments, this.props.departments) && this.props.departments.data.length > 0) {
            this.setState({ departmentIdSelected: this.props.departments.data[0].id }, this.updateListOfContracts);
        }
    }

    getAllHolidays = () => {
        Network.getAllHolidays(this.state.startMonth.startOf("year").format(MOMENT_MONTH_FORMAT), this.state.endMonth.endOf("month").format(MOMENT_MONTH_FORMAT)).then(
            (response: NetworkAllHolidays[]) => {
                this.setState({ allHolidays: convertNetworkAllHolidaysToAllHolidays(response) });
            },
            () => {
                this.setState({ allHolidays: undefined });
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the public holidays' }), "error");
            }
        )
    }

    getTypeOfDayOff = () => {
        Network.getTypeOfDayOff().then(
            response => {
                this.props.changeTypesOfDayOff!(response);
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the types of day' }), "warning")
        );
    }

    getAllIncreasedhoursByDay = () => {
        this.setState({ increasedhoursByDayLoading: true })
        Network.getAllIncreasedhoursByDay(this.state.startMonth.year()).then(
            (response: NetworkIncreasedHoursByDay[]) => {
                this.setState({ increasedhoursByDayLoading: false, increasedhoursByDay: convertNetworkAllIncreasedHoursByDayToAllIncreasedHoursByDay(response) });
            },
            () => {
                this.setState({ increasedhoursByDayLoading: false })
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the year {year}' }, { year: this.state.startMonth.year().toString() }), "warning")
            }
        );
    }

    updateListOfContracts = () => {
        const users = cloneDeep(this.props.users);

        const selectedJob: { userId: number, jobId: number | undefined }[] = [];
        users?.forEach((u: User) => {
            const contracts = cloneDeep(u.job);

            if (contracts && contracts.length > 0) {
                u.job = this.contractsAvailableBetweenMonths(contracts);
                selectedJob.push({
                    userId: u.id,
                    jobId: u.job[0]?.id
                });
            }
        });

        this.setState({ users, selectedJob });
    }

    /**
     * Automatic downlaod blob
     * @param blob the blob to download
     * @param filename the file name
     */
    downloadBlob = (blob: Blob, filename: string) => {
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
    }

    contractsAvailableBetweenMonths = (user_contracts: Array<UserJobTMP>) => {
        const { startMonth, endMonth } = this.state;
        let contracts = user_contracts.sort((a, b) => {
            const dateA = moment(a.date_in_report)
            const dateB = moment(b.date_in_report);
            return dateB.diff(dateA);
        });

        contracts = contracts.filter((c) => !(endMonth.isBefore(moment(c.date_in_report), "month") || startMonth.isAfter(moment(c.contract_expiry_date), "month")));
        if (this.props.departments.data.length > 0) {
            contracts = contracts.filter(c => c.department?.id === this.state.departmentIdSelected);
        }
        return contracts;
    }

    getInitialOvertimeHoursByUser = (userId: number, initialHours: InitialHoursCctSecurity[]) => {
        return initialHours?.filter(ih => ih.userId === userId).map(ih => {
            const initialHours: InitialHoursByContractByYear = {
                id: ih.id,
                initialHours: ih.initialOvertimeHours,
                year: ih.year,
                contractId: ih.contractId ? ih.contractId : -1
            }
            return initialHours;
        });
    }

    getInitialDaysOffByUser = (userId: number, initialDaysOff: InitialDaysOffCctSecurity[]) => {
        return initialDaysOff?.filter(ih => ih.userId === userId);
    }

    parseCctSecurityResponse = (cctSecurityResponse: CctSecurityResponse, mutiple: boolean, startMonth: Moment, endMonth: Moment): (undefined | CctSecurityEventsByContractByMonth[]) => {
        const { increasedhoursByDay } = this.state;
        const initialHours: InitialHoursByContractByYear[] = this.getInitialOvertimeHoursByUser(cctSecurityResponse.data[0].id, cctSecurityResponse.initialHours);
        const initialDaysOff: InitialDaysOffCctSecurity[] = this.getInitialDaysOffByUser(cctSecurityResponse.data[0].id, cctSecurityResponse.initialDaysOff);
        const allHolidays: AllHolidays[] = this.state.allHolidays ? cloneDeep(this.state.allHolidays) : [];
        if (mutiple) {
            // TODO
            showNotification("TODO", "error");
            return;
        } else {
            if (cctSecurityResponse.data.length === 1) {
                const userEventsData = new CctSecurity(cctSecurityResponse.data[0].events, {}, cctSecurityResponse.data[0].contracts);
                const contractsEvents = userEventsData.initCctSecurity(startMonth.clone().startOf("year"), endMonth.clone(), increasedhoursByDay ? increasedhoursByDay : [], allHolidays, this.state.monthlyHours, initialHours, initialDaysOff, this.props.typesOfDayOff)
                if (contractsEvents === undefined) {
                    showNotification("Problem dev", "error");
                    return;
                }

                return contractsEvents;
            }
        }
    }

    /**
     * Generate the reports for the selected users
     * @param user the concerned user, if one report must be generated - optional
     */
    generateReports = (user?: User) => {
        let userIds: number[] | undefined = [];

        //if a userId is specified, create an array with one id 
        if (user) {
            userIds.push(user.id);
            this.setState({ isLoading: user.id });
        }
        // else use the selected users array
        else {
            userIds = this.state.selectedUsers;
            this.setState({ isLoading: -1 });
        }
        const { selectedUsers } = this.state;
        if (selectedUsers.length > 0) {
            const multipleCcnt: CcntType[] = [];
            let departmenId: number | undefined = this.props.departments.data.length > 0 ? 0 : -1;
            if (departmenId === 0) departmenId = this.state.departmentIdSelected;
            Network.generateReportsCcntAccounting(this.state.startMonth.format("YYYY-MM-01"), this.state.endMonth.format("YYYY-MM-01"), userIds, departmenId).then(
                (networkCctSecurityResponse: NetworkCctSecurityResponse) => {
                    const cctSecurityResponse = convertNetworkCctSecurityResponseToCctSecurityResponse(networkCctSecurityResponse);

                    const test = this.parseCctSecurityResponse(cctSecurityResponse, true, this.state.startMonth, this.state.endMonth);
                    if (test) {
                        this.setState({ isLoading: undefined, selectedUsers: [], multipleCcnt, isMultipleCcntVisible: true });
                    }
                },
                () => {
                    this.setState({ isLoading: undefined, isMultipleCcntVisible: true });
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the report n°{user}' }, { user: user?.id }), "warning");
                }
            );
        } else {
            // TODO Temporary => to remove
            this.setState({ cctUser: user });
            this.setState({ isCcntVisible: true });
            let departmenId: number | undefined = this.props.departments.data.length > 0 ? 0 : -1;
            if (departmenId === 0) departmenId = this.state.departmentIdSelected;
            Network.generateReportsCcntAccounting(this.state.startMonth.format("YYYY-MM-01"), this.state.endMonth.format("YYYY-MM-01"), userIds, departmenId).then(
                (networkCctSecurityResponse: NetworkCctSecurityResponse) => {
                    const cctSecurityResponse = convertNetworkCctSecurityResponseToCctSecurityResponse(networkCctSecurityResponse);
                    const cctSecurityEventsByContractByMonth = this.parseCctSecurityResponse(cctSecurityResponse, false, this.state.startMonth, this.state.endMonth);

                    if (cctSecurityEventsByContractByMonth) {
                        this.setState({ isLoading: undefined, selectedUsers: [], cctSecurityEventsByContractByMonth });
                    }
                },
                () => {
                    alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                    this.setState({ isLoading: undefined });
                }
            );
        }
    }

    /**
     * Generate the company report for the selected users
     */
    generateCompanyReport = () => {
        this.setState({ isLoading: -2 });
        // generate reports
        Network.generateCompanyReport(this.state.startMonth.format("YYYY-MM-01")).then(
            blob => {
                this.downloadBlob(blob, `company_${this.state.startMonth.format("YYYY-MM")}.xlsx`);
                this.setState({ isLoading: undefined, selectedUsers: [] });
            },
            () => {
                alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while generating the reports' }), "warning");
                this.setState({ isLoading: undefined });
            }
        );
    }

    /**
     * Handle users filtering with search bar
     * @param event the triggered event
     */
    handleSearchUsers = (event: InputFieldOnChangeEvent) => {
        const search = event.target.value;
        if (search.length === 0) {
            this.setState({ searchedUsers: undefined, usersToDisplay: this.state.filteredGroups ? this.state.filteredGroups : undefined });
        } else {
            const searchedUsers = this.state.users?.filter(u => `${u.last_name}${u.first_name}`.toLowerCase().indexOf(search.toLowerCase()) >= 0);
            const usersToDisplay = this.state.filteredGroups ? this.state.filteredGroups.filter(u => `${u.last_name}${u.first_name}`.toLowerCase().indexOf(search.toLowerCase()) >= 0) : searchedUsers;
            this.setState({ usersToDisplay, searchedUsers });
        }
    }

    changeGroup = (values: number[]) => {
        if (values.length === 0) {
            this.setState({ selectGroups: values, filteredGroups: undefined, usersToDisplay: this.state.searchedUsers ? this.state.searchedUsers : undefined });
        } else {
            const filteredGroups = this.state.users?.filter(u => u.group_users ? u.group_users.some(gu => values.includes(gu.group)) : true);
            const usersToDisplay = this.state.searchedUsers ? this.state.searchedUsers.filter(u => u.group_users ? u.group_users.some(gu => values.includes(gu.group)) : true) : filteredGroups;
            this.setState({ selectGroups: values, usersToDisplay, filteredGroups });
        }
    }

    changeMonth = (month: Moment | null) => {
        if (month === null) return;

        const startMonth = month.startOf("month");
        let endMonth = startMonth.clone().endOf("month")

        if (startMonth.isAfter(endMonth, "days")) {
            endMonth = startMonth.clone().endOf('month');
        }

        this.setState({ startMonth, endMonth, ccnt: undefined, multipleCcnt: undefined, isCcntVisible: false, isMultipleCcntVisible: false }, () => {
            this.updateListOfContracts();
            this.getAllHolidays();

            Network.getMonthlyHours(this.state.startMonth.year()).then(
                (response: MonthlyHoursOfTheYear) => {
                    const monthlyHours: MonthlyHoursOfTheYearArray = {
                        id: response.id,
                        year: response.year,
                        hours: [
                            response.janHours ? response.janHours : 0,
                            response.febHours ? response.febHours : 0,
                            response.marHours ? response.marHours : 0,
                            response.aprHours ? response.aprHours : 0,
                            response.mayHours ? response.mayHours : 0,
                            response.junHours ? response.junHours : 0,
                            response.julHours ? response.julHours : 0,
                            response.augHours ? response.augHours : 0,
                            response.sepHours ? response.sepHours : 0,
                            response.octHours ? response.octHours : 0,
                            response.novHours ? response.novHours : 0,
                            response.decHours ? response.decHours : 0,
                        ]
                    }
                    this.setState({ monthlyHours: monthlyHours });
                },
                (error) => {
                    if (error.message.includes("No monthly hours")) {
                        this.setState({ monthlyHours: undefined });
                    } else {
                        alert(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the hours by year' }), "error");
                    }
                },
            );
        });
    }

    usersOnRow = (record: User) => ({
        onClick: () => this.generateReports(record)
    });

    onChangeSelectedUsers = (keys: React.Key[]) => this.setState({ selectedUsers: keys as number[] });

    columns = (): ColumnsType<User> => [
        {
            title: "",
            key: "image",
            dataIndex: 'image',
            fixed: this.props.isSmartphone ? undefined : "left",
            className: '__width_60 __centered-text',
            render: (link: string) => (
                <div onClick={e => { e.stopPropagation() }} className="both-center">
                    <Avatar size="large" src={link?.length > 0 && <Image onClick={e => e.stopPropagation()} sizes='small' preview={{ mask: <EyeOutlined /> }} height={'40px'} width={'40px'} src={link} style={{ objectFit: 'cover' }} />} icon={<UserOutlined />} style={{ backgroundColor: 'var(--primary-color)' }} />
                </div>
            ),
        },
        {
            title: <FormattedMessage defaultMessage={'Last name'} />,
            key: "last_name",
            dataIndex: 'last_name',
            className: '__width_200',
        },
        {
            title: <FormattedMessage defaultMessage={'First name'} />,
            key: "first_name",
            dataIndex: 'first_name',
            className: '__width_200',
        },
        {
            title: <FormattedMessage defaultMessage={'Dates'} />,
            key: "dates",
            className: '__min-width-250',
            render: (record: User) => {
                const { selectedJob } = this.state;
                const selectValue = selectedJob?.find(e => e.userId === record.id);
                const job = record.job?.find(e => e.id === selectValue?.jobId);

                if (job) return <span><FormattedMessage defaultMessage={'Contract from {start} to {end}'} values={{ start: moment(job.date_in_report, "YYYY-MM-DD").format(getFormat('DATE')), end: moment(job.contract_expiry_date, "YYYY-MM-DD").format(getFormat('DATE')) }} /></span>
                else return <span><FormattedMessage defaultMessage={'No contract selected'} /></span>
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Contract'} />,
            key: 'contract',
            className: '__width_280',
            fixed: this.props.isSmartphone ? undefined : "right",
            render: (record: User) => {
                if (record.job && record.job.length > 0) {
                    const { selectedJob } = this.state;
                    const selectValue = selectedJob?.find(e => e.userId === record.id);
                    return (
                        <Select
                            style={{ width: '250px' }}
                            value={selectValue?.jobId}
                            onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}
                            onChange={(e) => {
                                selectValue && (selectValue.jobId = e);
                                this.setState({ selectedJob });
                            }}
                        >
                            {record.job.map(contract => (
                                <Select.Option
                                    key={`contract-${contract.id}`}
                                    value={contract.id!}>
                                    {contract.name ? contract.name : <FormattedMessage defaultMessage={'Unamed contract'} />}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                } else return <span><FormattedMessage defaultMessage={'No contract available'} /></span>
            }
        }
    ]

    generateReportView = () => {
        const { multipleCcnt, isCcntVisible, isMultipleCcntVisible, isLoading } = this.state;
        let tableHeight = this.props.height - 342;
        if (tableHeight < 250) tableHeight = 250;

        return (
            <>
                <div>
                    <Table
                        dataSource={this.state.usersToDisplay?.filter(u => u.active === true && u.report === true) ?? this.state.users?.filter(u => u.active === true && u.report === true)}
                        columns={this.columns()}
                        rowKey={u => u.id}
                        rowSelection={{ type: 'checkbox', onChange: this.onChangeSelectedUsers, selectedRowKeys: this.state.selectedUsers }}
                        onRow={this.usersOnRow}
                        loading={this.props.usersLoading}
                        pagination={{
                            size: "small",
                            showLessItems: true,
                            defaultPageSize: this.props.height > 1130 ? 24 : 12,
                            pageSizeOptions: ["12", "24", "48", "96"],
                            showSizeChanger: true,
                            hideOnSinglePage: false,
                            showTotal: (total, range) => <FormattedMessage defaultMessage={'{range0}-{range1} of {total} users'} values={{ range0: range[0], range1: range[1], total }} />
                        }}
                        scroll={{ x: true, y: tableHeight }}

                    />
                    {
                        isMultipleCcntVisible &&

                        <MultipleCcntPrintPage multipleCcnt={multipleCcnt} close={() => this.setState({ isMultipleCcntVisible: false, multipleCcnt: undefined, modalDownloadMultipleVisible: false })} year={this.state.startMonth} />
                    }
                    {
                        <DrawerCctSecurity key={`drawer-cct-security-${isCcntVisible}`} startMonth={this.state.startMonth} user={this.state.cctUser} endMonth={this.state.endMonth} isLoading={Boolean(isLoading)} cctSecurity={this.state.cctSecurityEventsByContractByMonth} isVisible={isCcntVisible} close={() => this.setState({ isCcntVisible: false })} />
                    }
                </div>
                <Modal
                    onCancel={() => this.setState({ modalDownloadMultipleVisible: false, selectedUsers: [] })}
                    title={<FormattedMessage defaultMessage={'Download the users N-CLA report'} />}
                    footer={[
                        <Button type="dashed" onClick={() => this.setState({ modalDownloadMultipleVisible: false, selectedUsers: [] })} loading={Boolean(this.state.isLoading)} key="multiple-ccnt--cancel">
                            <FormattedMessage defaultMessage={'Cancel'} />
                        </Button>,
                        <Button type="primary" onClick={() => this.generateReports()} loading={Boolean(this.state.isLoading)} key="multiple-ccnt-download">
                            <FormattedMessage defaultMessage={'Download'} />
                        </Button>
                    ]}
                    visible={this.state.modalDownloadMultipleVisible}
                >
                    <Select
                        // disabled={this.props.loading}
                        mode="multiple"
                        maxTagCount="responsive"
                        allowClear
                        placeholder={<><FormattedMessage defaultMessage={'Users'} />{':'}</>}
                        style={{ width: '100%' }}
                        onChange={(e) => this.setState({ selectedUsers: e })}
                        value={this.state.selectedUsers}
                        filterOption={true}
                        optionFilterProp="label"
                        showArrow
                    >
                        {
                            this.props.users?.filter(u => u.active === true && u.report === true).map((u) => {
                                return <Select.Option label={u.first_name + " " + u.last_name} value={u.id} key={"users-select" + u.id}>{u.first_name + " " + u.last_name}</Select.Option>
                            })
                        }
                    </Select>
                </Modal>
                {
                    this.state.imageToOpen !== undefined && (
                        <Lightbox
                            mainSrc={this.state.imageToOpen}
                            onCloseRequest={() => this.setState({ imageToOpen: undefined })}
                        />
                    )
                }
            </>
        )

    }

    render() {
        const { selectedUsers, canChangeGroup } = this.state;
        const { width, isSmartphone } = this.props;
        return (
            <div>
                <Card icon={<span className='anticon'><SiMicrosoftexcel /></span>} title={<span><FormattedMessage defaultMessage={'Monthly reports'} /></span>} headerElements={[
                    <Space key={`monthly-report-header-elements`}>
                        {isSmartphone ?
                            null
                            :
                            <Button
                                style={width < 1565 ? { width: '100%', marginBottom: '5px' } : { width: '250px' }}
                                icon={<span className='anticon'><SiMicrosoftexcel /></span>}
                                onClick={() => this.generateCompanyReport()}
                                disabled={Boolean(this.state.isLoading)}
                                loading={this.state.isLoading === -2}>
                                <> <FormattedMessage defaultMessage={'Full planning'} /></>
                            </Button>
                        }
                        <DatePicker.MonthPicker
                            format={"MM/YYYY"}
                            style={width < 1565 ? { width: '100%', marginBottom: '5px' } : { width: '120px', marginRight: '5px' }}
                            allowClear={false}
                            value={this.state.endMonth.clone()}
                            onChange={this.changeMonth}
                        />
                    </Space>
                ]}
                >
                    <Row gutter={[10, 10]}>
                        <Col xs={{ span: 24 }} style={{ display: "flex", justifyContent: 'space-between', flexWrap: 'wrap', gap: '5px' }}>
                            <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                <InputField
                                    onChange={this.handleSearchUsers}
                                    style={width < 1565 ? { width: '100%' } : { width: '300px' }}
                                    type="search" />
                                <Select
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    placeholder={<FormattedMessage defaultMessage={'Groups'} />}
                                    style={width < 1565 ? { width: '100%' } : { width: '400px' }}
                                    onChange={this.changeGroup}
                                    value={this.state.selectGroups}
                                    filterOption={true}
                                    optionFilterProp="label"
                                    showArrow
                                    disabled={!canChangeGroup}
                                >
                                    {
                                        this.props.groups && this.props.groups.sort((a, b) => (a.name && b.name) ? a.name.localeCompare(b.name) : 0).map((g) => {
                                            return <Select.Option label={g.name} value={g.id!} key={"groups-" + g.id}>{g.name}</Select.Option>
                                        })
                                    }
                                </Select>
                            </div>
                            <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
                                <Button
                                    style={isSmartphone ? { width: '100%' } : {}}
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    onClick={() => this.generateReports()}
                                    disabled={this.state.isLoading !== undefined || selectedUsers === undefined || selectedUsers.length === 0}
                                    loading={this.state.isLoading === -1}>
                                    <FormattedMessage defaultMessage={'{count, plural, one {Download 1 report} other {Download {count} reports}}'} values={{ count: this.state.selectedUsers.length }} />
                                </Button>
                            </div>

                        </Col>
                        <Col xs={{ span: 24 }}>
                            {this.generateReportView()}
                        </Col>
                    </Row>
                </Card>
            </div >
        )
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
    toggleUsersLoading: (b: boolean) => dispatch(toggleUsersLoading(b)),
    loadDepartments: (fr?: boolean) => dispatch(loadDepartments(fr)),
    changeTypesOfDayOff: (t: TypeOfDayOff[]) => dispatch(changeTypesOfDayOff(t)),
});

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
    height: state.window.height,
    currentUser: state.user.currentUser,
    users: state.teamManagement.users,
    groups: state.teamManagement.groups,
    company: state.user.company,
    usersLoading: state.teamManagement.usersLoading,
    width: state.window.width,
    departments: state.configurations.departments,
    typesOfDayOff: state.configurations.typesOfDayOff,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(ReportTab)));