import { CloudSyncOutlined, InboxOutlined, LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Spin, Table, Tag } from 'antd';
import { ColumnGroupType, ColumnsType } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React from 'react';
import { TbBoxMultiple, TbBoxMultiple1, TbBoxMultiple2, TbBoxMultiple3, TbBoxMultiple4, TbBoxMultiple5, TbBoxMultiple6, TbBoxMultiple7, TbBoxMultiple8, TbBoxMultiple9 } from "react-icons/tb";
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeUsers } from '../../store/actions/teamManagement';
import Network from '../../utils/network';
import { RouterProps, User, UserHoursSummary, UserHoursSummaryWithContractDetails } from '../../utils/types/generalTypes';
import { NetworkUserHoursSummaries } from '../../utils/types/networkTypes';
import { GroupByDayMonthlyReportType, MonthlyReportEvent } from '../../utils/types/reportTypes';
import { ApplicationState, StoreDispatch, TeamManagementDispatchProps } from '../../utils/types/storeTypes';
import { showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import CircleButton from '../common/fields/circleButton';
import Anticon from '../common/general/anticon';
import Card from '../common/general/card';


const MULTIPLE_CONTRACTS_ICONS = [
    <TbBoxMultiple1 key="multipleContracsIcon1" />,
    <TbBoxMultiple2 key="multipleContracsIcon2" />,
    <TbBoxMultiple3 key="multipleContracsIcon3" />,
    <TbBoxMultiple4 key="multipleContracsIcon4" />,
    <TbBoxMultiple5 key="multipleContracsIcon5" />,
    <TbBoxMultiple6 key="multipleContracsIcon6" />,
    <TbBoxMultiple7 key="multipleContracsIcon7" />,
    <TbBoxMultiple8 key="multipleContracsIcon8" />,
    <TbBoxMultiple9 key="multipleContracsIcon9" />
];

interface IProps {
    users?: User[];
    isSmartphone?: boolean;
    height: number;
}

type Props = IProps & RouterProps & TeamManagementDispatchProps & IntlProps;

interface State {
    year: number;
    isLoading: boolean;
    hoursSummary: NetworkUserHoursSummaries;
    userContractDetails: {
        userId: number;
        summariesByContracts: UserHoursSummaryWithContractDetails[];
    }[];
    expandedUserIds: number[];
    loadingUserIds: number[];
    columns: ColumnsType<UserHoursSummary>;
}

/**
 * Component that represent the vacations tab for the report page
 */
class HoursTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            year: moment().year(),
            isLoading: false,
            hoursSummary: [],
            userContractDetails: [],
            expandedUserIds: [],
            loadingUserIds: [],
            columns: this.columnsUserHoursSummary()
        };
    }

    componentDidMount() {
        this.loadHoursSummary();
    }

    loadHoursSummary = () => {
        this.setState({
            isLoading: true,
            expandedUserIds: [],
            loadingUserIds: []
        });
        Network.usersHoursSummary(this.state.year).then(
            (response) => {
                this.setState({ hoursSummary: response, isLoading: false });
            }
        );
    };

    /**
     * It groups the events by day.
     * @param {MonthlyReportEvent[]} x - The array of MonthlyReportEvent objects to group.
     * @param {any} f - any
     * @returns An array of objects with a date and an array of events.
     */
    groupBy(x: MonthlyReportEvent[], f: any) {
        return x.reduce((a: GroupByDayMonthlyReportType[], b: MonthlyReportEvent) => {
            const found = a.find(t => t.date === f(b));
            if (found) {
                found.events.push(b);
            } else {
                a.push({
                    date: f(b),
                    events: [b]
                });
            }
            return a;
        }, []);
    }

    /**
     * It rounds a number to a given number of decimals.
     * @param {number} numberToDecimalize - the number to be converted to a decimal
     * @param {number} [nbDecimals=2] - the number of decimals to display.
     * @returns a string.
     */
    decimalize(numberToDecimalize: number, nbDecimals = 2, round = true) {
        if (round)
            return (Math.round(numberToDecimalize * 100) / 100).toFixed(nbDecimals);
        else
            return numberToDecimalize.toFixed(nbDecimals);
    }

    loadContractChangeDetails = (userId: number) => {
        // Closing
        if (this.state.hoursSummary.find(hs => hs.contract && hs.user.id === userId)) {
            this.setState({
                hoursSummary: this.state.hoursSummary.filter(hs => !hs.contract || (hs.contract && hs.user.id !== userId)),
                expandedUserIds: this.state.expandedUserIds.filter(u => u != userId)
            });

        } else { // Opening
            this.setState({ loadingUserIds: [...this.state.loadingUserIds, userId] });
            Network.userHoursSummaryWithContractDetails(userId, this.state.year)
                .then((response) => {
                    if (response.length > 0) {
                        const userIndex = this.state.hoursSummary.findIndex(s => s.user.id === userId);
                        const newHoursSummaries = cloneDeep(this.state.hoursSummary);
                        for (let i = 0; i < response.length; i++) {
                            const r = response[i];
                            newHoursSummaries.splice(userIndex + 1 + i, 0, { ...r.report, contract: r.contract });
                        }
                        this.setState({
                            hoursSummary: newHoursSummaries,
                            expandedUserIds: [...this.state.expandedUserIds, userId],
                            loadingUserIds: this.state.loadingUserIds.filter(u => u !== userId)
                        });
                    } else {
                        showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the contract' }), "error");
                    }
                });
        }
    };

    createMonthColumn = (month: number): ColumnGroupType<UserHoursSummary> => {
        const monthMoment = moment().month(month - 1);
        const key = monthMoment.format('MMM');

        const oddOrEven = month % 2 == 1 ? 'odd' : 'even';

        const headerText = monthMoment.format('MMMM');
        const header = <div style={{ flexDirection: 'column', display: 'flex' }}>
            {headerText[0].toUpperCase()}{headerText.substring(1, headerText.length).toLowerCase()}
        </div>;

        return {
            title: header,
            key: `${key}Hours`,
            className: `__report-hours-summary-fixed-${oddOrEven}-main`,
            children: [
                {
                    title: <FormattedMessage defaultMessage={'To do'} />,
                    key: `${key}Hours_todoHours`,
                    className: `__report-hours-summary-fixed-${oddOrEven}`,
                    render: (value, record) => {
                        const monthTotal = record.monthTotals.find(total => total.month === month);
                        if (monthTotal === undefined) {
                            return <span></span>;
                        } else {
                            return (
                                <span>{this.decimalize(monthTotal.todo, 2, true)}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Made'} />,
                    key: `${key}Hours_totalCalculatedBalance`,
                    className: `__report-hours-summary-fixed-${oddOrEven}`,
                    render: (value, record) => {
                        const monthTotal = record.monthTotals.find(total => total.month === month);
                        if (monthTotal === undefined) {
                            return <span></span>;
                        } else {
                            return (
                                <span>{this.decimalize(monthTotal.effectiveHours, 2, true)}</span>
                            );
                        }
                    }
                },
                {
                    title: <FormattedMessage defaultMessage={'Balance'} />,
                    key: `${key}Hours_nextBalance`,
                    className: `__report-hours-summary-fixed-${oddOrEven}`,
                    render: (value, record) => {
                        const monthTotal = record.monthTotals.find(total => total.month === month);
                        if (monthTotal === undefined) {
                            return <span></span>;
                        } else {
                            return (
                                <Tag className="__report-groups-users-tags">{this.decimalize(monthTotal.toReport, 2, true)}</Tag>
                            );
                        }
                    }
                }
            ]
        };
    };

    renderUserColumn = (record: UserHoursSummary) => {
        if (record.contract) {
            return (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} className='__contract-row'>
                    <p style={{ fontStyle: 'italic' }}>{record.contract.name}</p>
                </div>
            );
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>{record.user.fullName}</div>
            </div>
        );
    };

    getMultipleContractsIcon = (count: number) => {
        if (count > MULTIPLE_CONTRACTS_ICONS.length)
            return <TbBoxMultiple />;
        else
            return MULTIPLE_CONTRACTS_ICONS[count - 1];
    };

    renderWorkRateColumn = (record: UserHoursSummary) => {
        if (record.contract) {
            return <span>{record.contract.workRate}%</span>;
        } else if (record.contractChanges.length === 0) {
            return <span>{record.workRate}%</span>;
        } else {
            const contractChanges = record.contractChanges.length;
            let status = 'closed';
            if (this.state.loadingUserIds.includes(record.user.id)) {
                status = 'loading';
                console.log("LOADIIIIIIING");
            }
            else if (this.state.expandedUserIds.includes(record.user.id))
                status = 'opened';

            return (
                <div>
                    {
                        record.contractChanges.map(contractChange => (
                            <div key={`${record.user.id}_${contractChange.date}`}>
                                {/* <Badge className='__badge-spinner' count={status === 'opened' ? <div style={{ background: 'white', borderRadius: '100%' }}><AiOutlineCloseCircle /></div> : 0} offset={['-5px', '5px']}> */}
                                <CircleButton
                                    small
                                    withoutTooltip
                                    icon={<Anticon>{status === 'loading' ? <Spin /> : this.getMultipleContractsIcon(record.contractChanges.length)}</Anticon>}
                                    onClick={() => this.loadContractChangeDetails(record.user.id)}
                                    title={this.props.intl.formatMessage({ defaultMessage: '{count, plural, one {There is 1 contract change during the year} other {There is {count} contract changes during the year}}' }, { count: contractChanges })}
                                />
                                {/* </Badge> */}
                            </div>
                        ))
                    }

                </div>
            );
        }
    };

    renderInitialHoursColumn = (record: UserHoursSummary) => {
        if (record.contract) {
            return <Tag className='__report-groups-users-tags'>{record.monthTotals.length > 0 ? record.monthTotals[0].initialHours : 0}</Tag>;
        } else {
            return <Tag className='__report-groups-users-tags'>{record.monthTotals.length > 0 ? record.monthTotals[0].initialHours : 0}</Tag>;
        }
    };

    columnsUserHoursSummary = (): ColumnsType<UserHoursSummary> => [
        {
            title: <FormattedMessage defaultMessage={'Employee'} />,
            key: 'user',
            fixed: 'left',
            className: `__report-user __width_180 __no-padding`,
            render: (value, record) => this.renderUserColumn(record),
        },
        {
            title: <FormattedMessage defaultMessage={'Rate'} />,
            align: 'center',
            key: 'workRate',
            fixed: 'left',
            className: `report-user-background __width_80`,
            render: (_, record) => this.renderWorkRateColumn(record),
            onCell: (record) => {
                if (record.contractChanges.length > 0)
                    return {
                        colSpan: 2,
                        className: "__table-text-centered"
                    };

                return {
                    className: "__table-text-right"
                };
            }
        },
        {
            title: <FormattedMessage defaultMessage={'Initial'} />,
            key: 'inithours',
            align: 'center',
            fixed: 'left',
            className: `report-user-background __width_90 __table-text-right`,
            render: (_, record) => this.renderInitialHoursColumn(record),
            onCell: (record) => {
                if (record.contractChanges.length > 0)
                    return {
                        colSpan: 0
                    };

                return {};
            }
        },
        this.createMonthColumn(1),
        this.createMonthColumn(2),
        this.createMonthColumn(3),
        this.createMonthColumn(4),
        this.createMonthColumn(5),
        this.createMonthColumn(6),
        this.createMonthColumn(7),
        this.createMonthColumn(8),
        this.createMonthColumn(9),
        this.createMonthColumn(10),
        this.createMonthColumn(11),
        this.createMonthColumn(12),
        {
            title: <FormattedMessage defaultMessage={'To report'} />,
            key: 'to_report',
            fixed: 'right',
            className: '__report-hours-summary-to-report',
            render: (_, record) => {
                if (record.monthTotals === undefined) {
                    return <span></span>;
                } else {
                    return (
                        <Tag className={`__report-groups-users-tags${!record.contract ? '-important' : ''}`}>{record.monthTotals.length > 0 ? this.decimalize(record.monthTotals[record.monthTotals.length - 1].toReport, 2, false) : 0.0}</Tag>
                    );
                }
            }
        }
    ];


    render() {
        const { hoursSummary, isLoading, year } = this.state;
        const { isSmartphone, intl } = this.props;
        let tableHeight = this.props.height - 430;
        if (tableHeight < 250) tableHeight = 250;
        return (
            <div>
                <Card title={<FormattedMessage defaultMessage={'Hours reports'} />} icon={<UserOutlined />} headerElements={[
                    !isLoading && !isSmartphone ?
                        <>
                            {/* <p style={{ marginRight: "5px", fontSize: "85%", fontStyle: "italic" }}>Actualisé le {usersHoursSummaries?.updated.format("DD/MM/YYYY à HH:mm")}</p> */}
                            <Button
                                style={{ marginRight: "5px" }}
                                title={intl.formatMessage({ defaultMessage: 'Force refresh' })}
                                icon={<CloudSyncOutlined />}
                                onClick={this.loadHoursSummary}
                                disabled={isLoading} />
                        </>
                        : <p></p>,
                    <DatePicker
                        key={`hourstab-summary-header`}
                        disabled={isLoading}
                        style={isSmartphone ? { width: '85px' } : { width: '100px' }}
                        picker="year"
                        placeholder={intl.formatMessage({ defaultMessage: 'Year' })}
                        value={moment().year(year)}
                        onChange={(value) => this.setState({ year: value ? value.year() : moment().year() }, () => this.loadHoursSummary())}
                        allowClear={false} />
                ]}>
                    {
                        isLoading || hoursSummary.length <= 0 ?
                            <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", height: "200px" }}>
                                <Spin size='large' style={{ marginBottom: '20px' }} indicator={<LoadingOutlined style={{ fontSize: 90 }} spin />} />
                            </div>
                            :
                            hoursSummary.length > 0 ?
                                <Table
                                    dataSource={hoursSummary}
                                    columns={this.state.columns}
                                    loading={isLoading}
                                    pagination={false}
                                    rowKey={s => `report-hours-summary-${s.user.id}-c${s.contract?.id ?? 'none'}`}
                                    scroll={{ x: true, y: tableHeight }}
                                    rowClassName={(record) => record.contract ? '__user_report-child' : ''}
                                />

                                :
                                <span className="dashboard-empty">
                                    <InboxOutlined />
                                    <p><FormattedMessage defaultMessage={'No data'} /></p>
                                </span>
                    }

                </Card>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    changeUsers: (u: User[]) => dispatch(changeUsers(u)),
});

const mapStateToProps = (state: ApplicationState) => ({
    users: state.teamManagement.users,
    height: state.window.height,
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(HoursTab)));