import React, { CSSProperties, ReactNode } from 'react';
import Header from './header';
import Title from './title';

interface Props {
    className?: string;
    containerClassName?: string;
    style?: CSSProperties;
    icon: React.ReactNode;
    title: string | ReactNode;
    children: ReactNode;
    headerElements?: ReactNode[];
}

/**
 * Component that represent a card
 */
class Card extends React.Component<Props> {
    render() {
        return (
            <div style={this.props.style} className={`card-parent ${this.props.className ? this.props.className : ''}`}>
                <div className="card-header">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Header icon={this.props.icon} />
                        <Title>{this.props.title}</Title>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: '5px' }}>
                        {this.props.headerElements}
                    </div>
                </div>
                <div className="card-divider" />
                <div className={this.props.containerClassName}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
export default Card;