
import { EyeOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Image, Tag, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table/Column";
import cloneDeep from 'lodash/cloneDeep';
import moment, { Moment } from "moment";
import React from "react";
import isEqual from "react-fast-compare";
import { BiTimer } from "react-icons/bi";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import Selectable, { SelectableRef } from 'react-selectable-box';
import { toggleLoading } from "../../../../store/actions/user";
import { MOMENT_FORMAT_DISPLAY_TIME } from "../../../../utils/constants";
import getFormat from "../../../../utils/Lang";
import { Department, FastPlanningContract, RouterProps, UsersAvailability } from "../../../../utils/types/generalTypes";
import { NetworkAllHolidays } from "../../../../utils/types/networkTypes";
import { ContractData, EventCalculated, MonthlyPlanningByUserByDay, PartialProcessedMonthlyPlanningByUserByDay, PlanningEvent, ProcessedMonthlyPlanningByUserByDay, SelectedEventV2 } from "../../../../utils/types/planningTypes";
import { ApplicationState, StoreDispatch } from "../../../../utils/types/storeTypes";
import { isNullOrEmpty, isTouchDevice } from "../../../../utils/utils";
import { IntlProps } from "../../../app/LanguageProvider";
import Anticon from "../../../common/general/anticon";
import VirtualTable from "../../../common/general/virtualTableV2";
import Cell, { getEventSelectValue } from "./cell";


type ReduxProps = ConnectedProps<typeof connector>;

interface IProps extends ReduxProps, IntlProps {
    loadingStep: 'idle' | 'data' | 'availabilities' | 'stats' | 'sucess' | 'error',
    sider: boolean;
    filteredPlanningByUserByDay: MonthlyPlanningByUserByDay[] | undefined;
    displayMode: 'monthly' | 'weekly';
    date: Moment;
    currentDepartments?: Department[];
    allHolidays?: NetworkAllHolidays[];
    copy: {
        copiedEvents: PlanningEvent[] | undefined;
        onCopyEvent: (eventIds: number[]) => void;
        onCutEvent: (eventIds: number[], day: string) => void;
        resetCopyCut: () => void;
        cutEvent: string | undefined;
        onPasteEvent: (userId: number, day: string, forceUpdate?: boolean, skipCheckAvailability?: boolean, callbackSuccess?: () => void, callbackError?: () => void) => void;
    };
    selection: {
        onSelectMultiple: (data: { eventId: number, userId: number, date: string }[]) => void;
        onSelectCell: (eventId: number, userId: number, date: string) => void;
        onSelectRowCell: (eventId: number, userId: number, date: string) => void;
        selectedEvents: SelectedEventV2[];
    };
    usersUnavailabilities: UsersAvailability[];
    deleteEvent: (eventId: number, userId: number, day: string) => void;
    showEventDetails: (eventId?: number) => void;
    usersStats?: EventCalculated[];
    contracts?: FastPlanningContract[];
    onConfirmEvent: (eventId: number, userId: number) => void;
    onClickCreateEvent: (columnKey: string, userId: number) => void;
    onClickEditEvent: (eventId: number) => void;
    userIdsEditable?: number[];
    allUsersEditable: boolean;
    displayStats: boolean;
    mode?: 'add' | 'remove' | 'reverse';
}

interface State {
    columns: (ColumnProps<MonthlyPlanningByUserByDay> | null)[];
    contractsProcessed: ContractData[];
    processedPlanningByUserByDay: ProcessedMonthlyPlanningByUserByDay[];
    userColumn: ColumnProps<MonthlyPlanningByUserByDay> | null;
    dataColumns: ColumnProps<MonthlyPlanningByUserByDay>[];
    statColumn: ColumnProps<MonthlyPlanningByUserByDay> | null;
}

type Props = IProps & ReduxProps & RouterProps
class CalendarV3 extends React.PureComponent<Props, State> {
    nodeRef = React.createRef<SelectableRef>();

    constructor(props: Props) {
        super(props);

        this.state = {
            userColumn: null,
            dataColumns: [],
            statColumn: null,
            processedPlanningByUserByDay: [],
            contractsProcessed: [],
            columns: [],
        }
    }

    componentDidMount(): void {
        // console.log("PERF MOUNT Calendar")
        let contractsProcessed: ContractData[] | undefined = undefined;
        if (this.props.contracts)
            contractsProcessed = this.processContracts(false);

        const processedPlanningByUserByDay = this.processPlanningByUserByDay(false, contractsProcessed);
        const userColumn = this.genUserColumn(false, processedPlanningByUserByDay);
        const dataColumns = this.genDataColumns(false);
        const statColumn = this.genStatColumn(false);
        const columns = this.generateColumns(false, userColumn, dataColumns, statColumn);

        this.setState({ processedPlanningByUserByDay, userColumn, columns, dataColumns, statColumn, contractsProcessed: contractsProcessed ?? [] }, () => this.centerViewToToday());
    }

    shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
        if (!isEqual(nextState, this.state))
            return true;

        if (!isEqual(nextProps, this.props))
            return true;

        return false;
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if ((!isEqual(prevProps.filteredPlanningByUserByDay, this.props.filteredPlanningByUserByDay)) ||
            (!isEqual(prevProps.currentDepartments, this.props.currentDepartments)) ||
            (!isEqual(prevProps.contracts, this.props.contracts)) ||
            (!isEqual(prevProps.allHolidays, this.props.allHolidays)) ||
            (!isEqual(prevProps.displayStats, this.props.displayStats)) ||
            (!isEqual(prevProps.usersStats, this.props.usersStats)) ||
            (!isEqual(prevProps.displayMode, this.props.displayMode)) ||
            (prevProps.date.unix() !== this.props.date.unix())) {
            console.log("PERF PROCESS PlanningByUserByDay");
            let contractsProcessed = cloneDeep(this.state.contractsProcessed);
            let dataColumns = cloneDeep(this.state.dataColumns);
            if ((!isEqual(prevProps.currentDepartments, this.props.currentDepartments)) ||
                (!isEqual(prevProps.contracts, this.props.contracts)) ||
                (!isEqual(prevProps.allHolidays, this.props.allHolidays)) ||
                (!isEqual(prevProps.displayMode, this.props.displayMode)) ||
                (prevProps.date.unix() !== this.props.date.unix())) {
                if (this.props.contracts)
                    contractsProcessed = this.processContracts(false);
                if (prevProps.date.unix() !== this.props.date.unix() ||
                    !isEqual(prevProps.displayMode, this.props.displayMode) ||
                    !isEqual(prevProps.allHolidays, this.props.allHolidays))
                    dataColumns = this.genDataColumns(false);
            }

            const processedPlanningByUserByDay = this.processPlanningByUserByDay(false, contractsProcessed);

            const userColumn = this.genUserColumn(false, processedPlanningByUserByDay);

            let statColumn = cloneDeep(this.state.statColumn);
            if ((!isEqual(prevProps.displayStats, this.props.displayStats)) ||
                (!isEqual(prevProps.usersStats, this.props.usersStats))) {
                statColumn = this.genStatColumn(false);
            }

            const columns = this.generateColumns(false, userColumn, dataColumns, statColumn);

            this.setState({ processedPlanningByUserByDay, userColumn, columns, contractsProcessed, statColumn, dataColumns }, () => {
                if (prevProps.date.unix() !== this.props.date.unix())
                    this.centerViewToToday()
            });
        }

        if (
            (!isEqual(prevProps.isSmartphone, this.props.isSmartphone)) ||
            (!isEqual(prevProps.settings, this.props.settings)) ||
            (!isEqual(prevProps.currentUser, this.props.currentUser))
        ) {
            console.log("PERF PROCESS UserColumn");
            this.genUserColumn()
        }

        if (
            (!isEqual(prevProps.selection, this.props.selection)) ||
            (!isEqual(prevProps.allUsersEditable, this.props.allUsersEditable)) ||
            (!isEqual(prevProps.userIdsEditable, this.props.userIdsEditable)) ||
            (!isEqual(prevProps.usersUnavailabilities, this.props.usersUnavailabilities)) ||
            (!isEqual(prevProps.copy.copiedEvents, this.props.copy.copiedEvents))
        ) {
            console.log("PERF PROCESS DataColumns");
            const dataColumns = this.genDataColumns(false);
            const columns = this.generateColumns(false, undefined, dataColumns);

            this.setState({ dataColumns, columns });
        }
    }

    getStartDate = () => {
        const { displayMode, date } = this.props;

        return displayMode === 'monthly' ? date.clone().startOf('month') : date.clone().startOf('week');
    }
    getEndDate = () => {
        const { displayMode, date } = this.props;
        return displayMode === 'monthly' ? date.clone().endOf('month') : date.clone().endOf('week');
    }

    processContracts = (saveStateDirectly = true) => {
        const { currentDepartments, contracts, allHolidays } = this.props

        const start = this.getStartDate();
        const end = this.getEndDate();

        const flatCurrentDepartments = currentDepartments ? currentDepartments.filter(cD => cD.id !== undefined).map(cD => cD.id) as number[] : []
        const filteredContract = (currentDepartments !== undefined && currentDepartments.length > 0) ? contracts?.filter(uC => uC.departmentId && flatCurrentDepartments.includes(uC.departmentId)) : contracts
        const contractsData: ContractData[] = []
        filteredContract?.forEach(contract => {
            const contractStart = moment(contract.startDate).startOf('day')
            const contractEnd = moment(contract.endDate).endOf('day')
            const weeklyWorkingHours = contract.weeklyWorkingHours ? contract.weeklyWorkingHours / 100 * (contract.workRate ? contract.workRate : 100) : 0
            let effectiveStart = contractStart.clone()
            let effectiveEnd = contractEnd.clone()
            if ((contractEnd >= end || contractEnd === undefined) && contractStart <= start) {
                effectiveStart = start.clone()
                effectiveEnd = end.clone()
            } else if (contractEnd >= start && contractStart <= start) {
                effectiveStart = start.clone()
            } else if (contractEnd <= end && contractStart >= start) {
                console.log('effectiveTime is already correct')
            } else if ((contractEnd >= end || contractEnd === undefined) && contractStart >= start) {
                effectiveEnd = end.clone()
            } else {
                console.log("ELSE", contract, start, end)
            }
            let iterator = effectiveStart
            let workingDays = 0

            for (iterator = effectiveStart.clone(); iterator < effectiveEnd; iterator = iterator.add(1, 'day')) {
                if ([1, 2, 3, 4, 5].includes(iterator.isoWeekday())) {
                    if (allHolidays === undefined || allHolidays.find(holiday => (iterator.isSame(moment(holiday.startDate), 'day'))) === undefined) {
                        workingDays += 1
                    } else {
                        console.log('REMOVEDATE ', iterator)

                    }
                }
            }
            contractsData.push({
                userId: contract.userId,
                contract: contract,
                daysUnderThisContract: workingDays,
                start: effectiveStart,
                end: effectiveEnd,
                weeklyWorkingHours
            })
        })
        if (saveStateDirectly)
            this.setState({ contractsProcessed: contractsData });

        return contractsData;

    }

    processPlanningByUserByDay = (saveStateDirectly = true, contractsProcessed?: ContractData[]) => {
        const { filteredPlanningByUserByDay } = this.props
        const start = this.getStartDate();
        const end = this.getEndDate();
        let contractsProcessedTmp = this.state.contractsProcessed;
        if (contractsProcessed) {
            contractsProcessedTmp = contractsProcessed;
        }

        const processedPlanningByUserByDay = filteredPlanningByUserByDay ? filteredPlanningByUserByDay.map((planningByUserByDay: MonthlyPlanningByUserByDay) => {
            const contractsData = contractsProcessedTmp.filter(contract => contract.userId === planningByUserByDay.userId);

            const displayContractInformation = contractsData.some(c => c.weeklyWorkingHours !== 0)
            const workingHours = contractsData.reduce((value, contract) => {
                return value += contract.weeklyWorkingHours / 5 * (contract.daysUnderThisContract)
            }, 0)

            const newplanningByUserByDay = this.calculateUserStats({ ...planningByUserByDay, contractsData })
            const workedHours = (newplanningByUserByDay.workedHours ? newplanningByUserByDay.workedHours : 0)

            const workedDiff = workedHours - (workingHours ?? 0)
            //const missingEvent =
            let eventAccumulatedDuration = moment.duration(workedHours, 'hours').format(MOMENT_FORMAT_DISPLAY_TIME)
            if (eventAccumulatedDuration.length === 2) {
                eventAccumulatedDuration = '00:' + eventAccumulatedDuration
            } else if (eventAccumulatedDuration.length === 3) {
                eventAccumulatedDuration = '-00:' + String((Number(eventAccumulatedDuration) * -1))
            }

            let eventDifference = moment.duration(workedDiff, 'hours').format(MOMENT_FORMAT_DISPLAY_TIME)
            if (eventDifference.length == 2) {
                eventDifference = '00:' + eventDifference
            } else if (eventDifference.length === 3) {
                eventDifference = '-00:' + String((Number(eventDifference) * -1))
            }
            const statedDiff = Number(moment.duration(workedDiff, 'hours').format('HHmm'))


            const fullname = `${planningByUserByDay.userLastName} ${planningByUserByDay.userFirstName}`;

            return ({
                ...newplanningByUserByDay,
                fullname,
                workedDiff,
                eventAccumulatedDuration,
                eventDifference,
                statedDiff,
                contractsData,
                workingHours,
                start: start.clone(),
                end: end.clone(),
                displayContractInformation
            })
        }) : [];

        if (saveStateDirectly)
            this.setState({ processedPlanningByUserByDay: processedPlanningByUserByDay as ProcessedMonthlyPlanningByUserByDay[] })

        return processedPlanningByUserByDay as ProcessedMonthlyPlanningByUserByDay[]


    }

    calculateUserStats = (planningByUserByDay: PartialProcessedMonthlyPlanningByUserByDay): PartialProcessedMonthlyPlanningByUserByDay => {
        //console.log('CALCULATE STATS')
        const { usersStats } = this.props

        const eventsOnRow = planningByUserByDay ? planningByUserByDay.allEvents : []
        const flatEventIdOnRow = eventsOnRow ? Array.from(new Set(eventsOnRow.map(e => e.id))) : []
        if (usersStats === undefined) return {
            ...planningByUserByDay,
            flatEventIdOnRow,
        }
        const userStats = usersStats ? usersStats.filter(stat => stat.userId === planningByUserByDay.userId) : undefined;

        let workedHours = 0
        const flatEventCalculated: number[] = []
        userStats?.forEach(calculated => {
            if (flatEventIdOnRow.includes(calculated.id) && calculated.cAWT) {
                workedHours += calculated.tHWOWNPB;
                flatEventCalculated.push(calculated.id);
            }
        })
        return ({
            ...planningByUserByDay,
            flatEventCalculated,
            flatEventIdOnRow,
            workedHours
        })

    }

    renderDuration = (record: PartialProcessedMonthlyPlanningByUserByDay) => {
        const { displayContractInformation, workingHours, eventAccumulatedDuration, statedDiff, eventDifference } = record;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                <span style={{ fontSize: 14 }}>
                    <span title={this.props.intl.formatMessage({ defaultMessage: 'Duration of events on the line, excluding breaks.' })}>{eventAccumulatedDuration}</span>
                    <span>{`${displayContractInformation ? ` / ${moment.duration(workingHours, 'hours').format(getFormat('TIME_SHORT'))}` : ''}`}</span>
                </span>
                {displayContractInformation &&
                    <Tooltip title={
                        <div>
                            <p><FormattedMessage defaultMessage={'Event duration (excluding unpaid breaks and including confirmed overtime)'} /></p>
                            <p><FormattedMessage defaultMessage={' - In red: positive overtime'} /></p>
                            <p><FormattedMessage defaultMessage={' - In blue: negative overtime'} /></p>
                        </div>
                    }>
                        <Tag color={statedDiff === 0 ? 'green' : (statedDiff !== undefined ? statedDiff : 0) < 0 ? 'blue' : 'volcano'} style={{ fontSize: 12, marginRight: 0 }}>{`${(statedDiff !== undefined ? statedDiff : 0) > 0 ? '+' : ''}${eventDifference}`}</Tag>

                    </Tooltip>
                }
            </div>
        );
    }

    genStatColumn = (saveStateDirectly = true) => {
        const { displayStats } = this.props
        let statColumn: ColumnProps<MonthlyPlanningByUserByDay> | null = null
        if (displayStats) {
            statColumn = ({
                fixed: 'right',
                className: '__monthly-planning-column-users-stats __width_180',
                title: <Anticon style={{ fontSize: '25px', color: 'white' }} icon={<BiTimer />} />,
                key: 'statsColumns',
                width: 180,
                render: (record: PartialProcessedMonthlyPlanningByUserByDay) => {
                    return (
                        <div style={{
                            width: 'calc(100% - 20px)',
                            height: 'calc(100% - 20px)',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center'

                        }}>
                            {this.renderDuration(record)}
                        </div>
                    )
                }
            })

        }

        if (saveStateDirectly)
            this.setState({ statColumn });

        return statColumn;
    }

    genDataColumns = (saveStateDirectly = true) => {
        const { displayMode, date } = this.props

        const dataColumns: ColumnProps<MonthlyPlanningByUserByDay>[] = []
        if (displayMode === 'monthly') {
            new Array(date.clone().daysInMonth()).fill(null).map((x, i) => `${date.clone().startOf('month').add(i, 'days').format("YYYYMMDD")}`).forEach((item) => {
                dataColumns.push(this.createDataColumnItem(item))
            });
        } else if (displayMode === 'weekly') {
            new Array(7).fill(null).map((x, i) => `${date.clone().startOf('week').add(i, 'days').format("YYYYMMDD")}`).forEach((item) => {
                dataColumns.push(this.createDataColumnItem(item))
            });
        }
        if (saveStateDirectly)
            this.setState({ dataColumns });

        return dataColumns;
    }

    genUserColumn = (saveStateDirectly = true, processedPlanningByUserByDay?: ProcessedMonthlyPlanningByUserByDay[]) => {
        let processedPlanningByUserByDayTmp = this.state.processedPlanningByUserByDay;
        if (processedPlanningByUserByDay !== undefined) {
            processedPlanningByUserByDayTmp = processedPlanningByUserByDay;
        }
        const { isSmartphone, settings, currentUser } = this.props;

        const userColumn: ColumnProps<MonthlyPlanningByUserByDay> = ({
            title: <FormattedMessage defaultMessage={'Users ({count})'} values={{ count: processedPlanningByUserByDayTmp ? processedPlanningByUserByDayTmp.length : 0 }} />,
            // dataIndex: 'userId',
            key: `userId`,
            fixed: isSmartphone ? undefined : 'left',
            width: 260,
            render: (record: PartialProcessedMonthlyPlanningByUserByDay, r: MonthlyPlanningByUserByDay) => {
                const { fullname, contractsData, flatEventCalculated, flatEventIdOnRow } = record
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <div onClick={e => { e.stopPropagation() }} className="both-center">
                            {settings.showAvatars ? <Avatar size="large" src={r && r.userImage && r.userImage.length > 0 && <Image onClick={e => e.stopPropagation()} sizes='small' preview={{ mask: <EyeOutlined /> }} height={'40px'} width={'40px'} src={r.userImage} style={{ objectFit: 'cover' }} />} icon={<UserOutlined />} style={{ backgroundColor: 'var(--primary-color)' }} /> : <div style={{ width: 40, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><UserOutlined style={{ fontSize: '32px' }} /></div>}
                        </div>
                        <div>
                            <Tooltip
                                mouseEnterDelay={1}
                                mouseLeaveDelay={0}
                                title={
                                    <>
                                        <p><b>{fullname}</b></p>
                                        {contractsData.map((contractData, i) => {
                                            if (record.userId === 38978) console.log("CONTRACTDATA", contractData.contract.endDate)
                                            return (
                                                <span
                                                    key={`contract-data-${contractData.contract.id}-${contractData.daysUnderThisContract}-days`}
                                                >
                                                    <span
                                                        title={
                                                            `${contractData.contract.name}\n
                                                            ${this.props.intl.formatMessage({ defaultMessage: 'From {start}' }, { start: moment(contractData.contract.startDate).format(getFormat('DATE_LONG_AND_ON_TIME_LONG')) })}\n
                                                            ${this.props.intl.formatMessage({ defaultMessage: 'Until {end}' }, { end: moment(contractData.contract.endDate).format(getFormat('DATE_LONG_AND_ON_TIME_LONG')) })}\n
                                                            ${contractData.contract.workRate && this.props.intl.formatMessage({ defaultMessage: 'Working {hoursByWeek} at {precent} {hoursByMonth}' }, { hoursByWeek: contractData.contract.weeklyWorkingHours && this.props.intl.formatMessage({ defaultMessage: '{count} hours a week' }, { count: contractData.contract.weeklyWorkingHours, hoursByMonth: (contractData.contract.workRate !== 100 && contractData.contract.workRate !== 0 && contractData.contract.workRate != null && contractData.contract.weeklyWorkingHours != null && contractData.contract.weeklyWorkingHours !== 0) ? this.props.intl.formatMessage({ defaultMessage: ', i.e. {hours} hours.' }, { hours: contractData.contract.weeklyWorkingHours && contractData.contract.workRate ? (contractData.contract.weeklyWorkingHours / 100 * contractData.contract.workRate) : '' }) : '.' }), precent: contractData.contract.workRate ? contractData.contract.workRate : 100, hoursByMonth: contractData.contract.workRate !== 100 && contractData.contract.workRate !== 0 && contractData.contract.workRate != null && contractData.contract.weeklyWorkingHours != null && contractData.contract.weeklyWorkingHours !== 0 ? this.props.intl.formatMessage({ defaultMessage: 'or {hours} hours' }, { hours: contractData.contract.weeklyWorkingHours && contractData.contract.workRate ? contractData.contract.weeklyWorkingHours / 100 * contractData.contract.workRate : '' }) : '.' })}`}
                                                        style={{ fontWeight: 600 }}>
                                                        <FormattedMessage defaultMessage={'Contract {num}'} values={{ num: i + 1 }} />
                                                    </span>
                                                    {contractData.contract.workRate && <span>{` à ${contractData.contract.workRate}%`}</span>}
                                                    <span>
                                                        <FormattedMessage defaultMessage={'Applied for {count} days (from {start} to {end})'} values={{ count: contractData.daysUnderThisContract, start: contractData.start.format(getFormat('DATE_LONG_AND_ON_TIME_LONG')), end: contractData.end.format(getFormat('DATE_LONG_AND_ON_TIME_LONG')) }} />
                                                    </span>
                                                </span>
                                            )
                                        })}
                                    </>
                                }
                            >
                                <p
                                    style={{ cursor: (currentUser && currentUser.role === 2) ? 'pointer' : 'default' }}
                                    onClick={(currentUser && currentUser.role === 2) ? () => this.props.history.push(`/${this.props.match.params.lang}/team-management/user-details/informations?id=${record.userId}`) : undefined}
                                    className='__monthly-planning-column-name'
                                >{fullname}</p>
                            </Tooltip>
                            <p className='__monthly-planning-column-name-desc'>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
                                    <span title={
                                        `
                                        ${this.props.intl.formatMessage({ defaultMessage: '{count, plural, one {1 event total} other {{count} events total}}' }, { count: flatEventIdOnRow && flatEventIdOnRow.length || 0 })}\n
                                        ${this.props.intl.formatMessage({ defaultMessage: '{count, plural, one {1 event is included in the reports} other {{count} events are included in the reports}}' }, { count: flatEventCalculated && flatEventCalculated.length || 0 })}
                                        `
                                    }>
                                        <FormattedMessage defaultMessage={'{count, plural, one {1 event ({qty})} other {{count} events ({qty})}}'} values={{ count: flatEventIdOnRow && flatEventIdOnRow.length || 0, qty: flatEventCalculated && flatEventCalculated.length }} />
                                    </span>
                                </div>
                            </p>
                        </div>
                    </div >
                );
            }
        })

        if (saveStateDirectly)
            this.setState({ userColumn });

        return userColumn;
    }

    createDataColumnItem = (item: string) => {
        const { copy, allHolidays, allUsersEditable, userIdsEditable, usersUnavailabilities, deleteEvent, showEventDetails, onClickCreateEvent, onClickEditEvent, selection, onConfirmEvent } = this.props
        const { selectedEvents, onSelectCell, onSelectRowCell } = selection

        const today = `${moment().format("YYYYMMDD")}`;

        const tmpDate = moment(item, 'YYYYMMDD');
        const dayOfWeek = tmpDate.isoWeekday();
        const isWeekend = (dayOfWeek === 6 || dayOfWeek === 7);
        const isPublicHoliday = allHolidays?.some(h => tmpDate.isSame(moment(h.startDate), 'day'));

        const isToday = today === item && moment().format("YYYYMMDD") === tmpDate.format("YYYYMMDD");
        let title = "";
        isToday && (title += "Aujourd'hui");
        isWeekend && (title += `${title.length > 0 ? ' & ' : ''}Weekend`);
        isPublicHoliday && (title += `${title.length > 0 ? ' & ' : ''}Férié`);

        return ({
            className: `__monthly-planning-one-cell ${isToday ? '__monthly-planning-column-today' : ''} ${isWeekend ? '__monthly-planning-column-weekend' : ''} ${isPublicHoliday ? '__monthly-planning-column-public-holiday' : ''}`,
            title: <span title={title} style={isToday ? { fontWeight: 'bold' } : {}}>{tmpDate.format("ddd DD")}</span>,
            key: item,
            width: 165,
            render: (value: MonthlyPlanningByUserByDay) => {

                if (value) {
                    const data = (value.cellEvents && value.cellEvents[item]) ? value.cellEvents[item] : undefined;
                    return (
                        <Cell
                            canEdit={allUsersEditable === true || (value.userId !== undefined && userIdsEditable !== undefined && userIdsEditable.includes(value.userId))}
                            item={item}
                            usersUnavailabilities={usersUnavailabilities}
                            userId={value.userId}
                            data={data}
                            canPaste={!isNullOrEmpty(copy.copiedEvents)}
                            deleteEvent={deleteEvent}
                            showEventDetails={showEventDetails}
                            onCopyEvent={copy.onCopyEvent}
                            onCutEvent={copy.onCutEvent}
                            cutEvent={copy.cutEvent ? true : false}
                            onPasteEvent={copy.onPasteEvent}
                            resetCopyCut={copy.resetCopyCut}
                            copiedEvents={copy.copiedEvents}
                            onClickCreateEvent={onClickCreateEvent}
                            onClickEditEvent={onClickEditEvent}
                            onSelectCell={onSelectCell}
                            onSelectRowCell={onSelectRowCell}
                            onConfirmEvent={onConfirmEvent}
                            selectedEvents={selectedEvents}
                        />
                    );
                } else {
                    return null;
                }
            },
        })
    }

    generateColumns = (saveStateDirectly = true, userColumn?: ColumnProps<MonthlyPlanningByUserByDay>, dataColumns?: ColumnProps<MonthlyPlanningByUserByDay>[], statColumn?: ColumnProps<MonthlyPlanningByUserByDay> | null) => {
        let dataColumnsTpm = this.state.dataColumns;
        if (dataColumns !== undefined)
            dataColumnsTpm = dataColumns;
        let statColumnTpm = this.state.statColumn;
        if (statColumn !== undefined)
            statColumnTpm = statColumn;
        let userColumnTmp = this.state.userColumn;
        if (userColumn !== undefined)
            userColumnTmp = userColumn;

        const columns: (ColumnProps<MonthlyPlanningByUserByDay> | null)[] = [
            userColumnTmp,
            ...dataColumnsTpm
        ]


        if (statColumnTpm != null) columns.push(statColumnTpm)

        if (saveStateDirectly)
            this.setState({ columns });

        return columns;
    }

    centerViewToToday = () => {
        const { displayMode, date } = this.props
        const isThisDate = (displayMode === 'monthly') ?
            moment().format("YYYYMM") === date.format("YYYYMM")
            :
            (displayMode === 'weekly') ?
                moment().week() === date.week()
                :
                moment().format("YYYYMMDD") === date.format("YYYYMMDD") //for futur daily
        let goStartOfTable = false;
        if (isThisDate) {
            const columnIndex = moment().date() + 1; // +1 compensating the user column

            const targetColumn = document.querySelector(`.ant-table-body table tbody tr:first-child td:nth-child(${columnIndex})`);
            if (targetColumn) {
                targetColumn.scrollIntoView({ inline: "center" });
            } else {
                goStartOfTable = true;
            }
        } else {
            goStartOfTable = true;
        }

        if (goStartOfTable) {
            const targetColumn = document.querySelector(`.ant-table-body table tbody tr:first-child td:nth-child(1)`);
            if (targetColumn) {
                targetColumn.scrollIntoView({ inline: "start" });
            }
        }
    }

    render() {
        const { height, sider, mode, selection, copy, isSmartphone } = this.props
        const { processedPlanningByUserByDay, columns } = this.state;
        return (
            <Selectable
                disabled={!isNullOrEmpty(copy.copiedEvents) || isSmartphone || isTouchDevice()}
                mode={mode}
                ref={this.nodeRef}
                scrollContainer={() => document.getElementsByClassName('ant-table-body')[0] as HTMLElement}
                value={selection.selectedEvents.map(se => getEventSelectValue(se.userId, se.eventId, se.date))}
                onStart={() => {
                    mode === undefined && this.props.selection.onSelectMultiple([]);
                }}
                onEnd={(selectingValue, { added, removed }) => {
                    const selectedEvents = cloneDeep(selection.selectedEvents);
                    const addedToEvent: SelectedEventV2[] = added.map(a => {
                        const [userId, eventId, date] = a.split('-').map(part => part);
                        return { eventId: parseInt(eventId), userId: parseInt(userId), date };
                    });
                    const result = selectedEvents.concat(addedToEvent).filter((i) => !removed.includes(getEventSelectValue(i.userId, i.eventId, i.date)));
                    this.props.selection.onSelectMultiple(result);
                }}

            >
                <div className={`__monthly-planning-main-table-container ${sider ? 'filters' : 'nofilters'}`}>
                    <VirtualTable
                        id="planning-table-scroll"
                        bordered={true}
                        size='small'
                        className={`__monthly-planning-main-table ${this.props.selection.selectedEvents.length > 0 ? '__monthly-planning-selection-activated' : ''} ${!isNullOrEmpty(this.props.copy.copiedEvents) ? '__monthly-planning-copy-activated' : ''} ${this.props.selection.selectedEvents.length === 0 && isNullOrEmpty(this.props.copy.copiedEvents) ? '__monthly-planning-actions-activated' : ''}`}
                        scroll={{ y: height - 265 }}
                        // loading={loadingMonthlyPlanning || (!this.state.editEvent && this.state.editEventLoading)}
                        // key={`table-courses-${start.format('YYYYMMDD')}-${end.format('YYYYMMDD')}`}
                        rowKey={(p: MonthlyPlanningByUserByDay) => `${p.userId}`}
                        dataSource={processedPlanningByUserByDay}
                        columns={columns != null ? columns as ColumnProps<MonthlyPlanningByUserByDay>[] : [this.genUserColumn()]}
                    />
                </div>
            </Selectable>
        )
    }


}
const mapDispatchToProps = (dispatch: StoreDispatch) => ({
    toggleLoading: (b: boolean) => dispatch(toggleLoading(b)),

});
const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    isSmartphone: state.window.isSmartphone,
    company: state.user.company,
    settings: state.planning.settings,
    height: state.window.height
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withRouter(injectIntl(CalendarV3)));