import { Alert, Popover, Spin } from 'antd';
import Tree, { DataNode } from 'antd/lib/tree';
import moment, { Moment } from 'moment';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../../rbacRules';
import { BLUE_COLOR, GREEN_COLOR, RED_COLOR, YELLOW_COLOR } from '../../../utils/constants';
import getFormat from '../../../utils/Lang';
import Network from '../../../utils/network';
import { IMissionRuleType, IMissionStatus } from '../../../utils/types/customerTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import { showNotification } from '../../../utils/utils';
import Can from '../../common/general/can';
import SpaceContent from '../../common/general/spaceContent';
import MissionFilters from './missionFilters';


type GroupedData<T, I> = { key: string; title: string; data: GroupedData<T, I>[]; items: I[] };

type ReduxProps = ConnectedProps<typeof connector>;
interface Props extends ReduxProps {
    startDate: Moment;
    endDate: Moment;
    refresh?: boolean;
    endRefresh?: () => void;
    newEvent?: (ruleId: number, date: Moment, templateId?: number) => void;
}

interface State {
    loading: boolean;
    missionsStatus: IMissionStatus[];
    missionsParsed: GroupedData<IMissionStatus, IMissionStatus>[];
    filteredMissionsStatus?: IMissionStatus[];
    treeData: DataNode[];
    filteredTreeData: DataNode[];
    expandedKeys: React.Key[];
    refreshMissionsStatus: boolean;
}

class MissionControl extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            missionsStatus: [],
            missionsParsed: [],
            treeData: [],
            filteredTreeData: [],
            expandedKeys: [],
            refreshMissionsStatus: false,
        }
    }

    componentDidMount() {
        this.getMissionsStatus();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if ((this.props.refresh !== prevProps.refresh && this.props.refresh) ||
            (!this.props.startDate.isSame(prevProps.startDate, "day")) ||
            (!this.props.endDate.isSame(prevProps.endDate, "day"))) {
            this.getMissionsStatus();
            this.props.endRefresh && this.props.endRefresh();
        }
    }

    groupBy<T, KKey extends keyof T, KTitle extends keyof T>(arr: T[], lookupKey: KKey, titleKey: KTitle): GroupedData<T, T>[] {
        const groupedArr: GroupedData<T, T>[] = []

        for (let i = 0; i < arr.length; i++) {
            const item = arr[i]
            const currentKey = String(item[lookupKey]);
            const grouped = groupedArr.find(g => g.key == currentKey)
            if (grouped) {
                grouped.items.push(item)
            } else {
                groupedArr.push({ title: String(item[titleKey]), key: currentKey, items: [item], data: [] })
            }
        }

        return groupedArr
    }
    genTreeData = (missionsParsed: GroupedData<IMissionStatus, IMissionStatus>[]) => {
        const expandedKeys: string[] = [];

        const treeData: DataNode[] = missionsParsed.map(date => {
            const dateKey = `date-${date.key}`;
            expandedKeys.push(dateKey);
            return {
                title: <p style={{ backgroundColor: 'var(--primary-btn-background-color)', opacity: '0.9', color: 'white', width: '100%', flex: '1', padding: '0 10px', borderRadius: 'var(--border-radius)' }}>{moment(date.title).format(getFormat('DATE'))}</p>,
                key: `date-${date.key}`,
                children: date.data.map(customer => {
                    const customerKey = `date-${date.key}-customer-${customer.key}`;
                    expandedKeys.push(customerKey);
                    return {
                        title: customer.title,
                        key: `date-${date.key}-customer-${customer.key}`,
                        children: customer.data.map(mandate => {
                            const mandatekey = `date-${date.key}-customer-${customer.key}-mandate-${mandate.key}`;
                            expandedKeys.push(mandatekey);
                            return {
                                title: mandate.title,
                                key: `date-${date.key}-customer-${customer.key}-mandate-${mandate.key}`,
                                children: mandate.data.map(mission => {
                                    // TODO: For the moment, we're only checking the first rule, as it's virtually impossible to have 2 rules of different types.
                                    const m = mission.items.at(0);
                                    if (!m) {
                                        return {
                                            title: '',
                                            key: `date-${date.key}-customer-${customer.key}-mandate-${mandate.key}-mission-${mission.key}`
                                        };
                                    }
                                    let backgroundColor = GREEN_COLOR;
                                    const dontCheckHumans = m.type === IMissionRuleType.QUOTA ? true : false;
                                    if ((m.remainingHumanToHave > 0 || dontCheckHumans) && m.remainingTimeToDo > 0) {
                                        backgroundColor = RED_COLOR;
                                    } else if ((m.remainingHumanToHave > 0 && dontCheckHumans) || m.remainingTimeToDo > 0) {
                                        backgroundColor = YELLOW_COLOR;
                                    } else if ((m.remainingHumanToHave < 0 || dontCheckHumans) && m.remainingTimeToDo < 0) {
                                        backgroundColor = BLUE_COLOR
                                    }

                                    const ruleKey = `date-${date.key}-customer-${customer.key}-mandate-${mandate.key}-mission-${mission.key}-rule-${m.ruleId}`;
                                    expandedKeys.push(ruleKey);
                                    return {
                                        title: (
                                            <SpaceContent style={{ justifyContent: 'space-between', width: '100%' }} key={`mission-${m.ruleId}-${m.startDate}}`} onClick={() => this.clickOnMissionRule(m.ruleId, m.startDate, m.templateId)}>
                                                <p title={m.missionTitle} style={{ maxWidth: '230px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{m.missionTitle}</p>
                                                <Popover
                                                    placement='left'
                                                    mouseEnterDelay={0.3}
                                                    mouseLeaveDelay={0}
                                                    content={
                                                        <>
                                                            {
                                                                dontCheckHumans ?
                                                                    null
                                                                    :
                                                                    <SpaceContent space={10}>
                                                                        <div style={{ width: '10px', height: '10px', backgroundColor: m.remainingHumanToHave > 0 ? RED_COLOR : m.remainingHumanToHave < 0 ? BLUE_COLOR : GREEN_COLOR, borderRadius: '100%', cursor: 'pointer' }} />
                                                                        <p><FormattedMessage defaultMessage={'{count, plural, one {There are 1 people planned out of the {planned} planned} other {There are {count} peoples planned out of the {planned} planned}}'} values={{ count: m.totalizedEventsTotalHumans, planned: m.humanToHave }} /></p>
                                                                    </SpaceContent>
                                                            }
                                                            <SpaceContent space={10}>
                                                                <div style={{ width: '10px', height: '10px', backgroundColor: m.remainingTimeToDo > 0 ? RED_COLOR : m.remainingTimeToDo < 0 ? BLUE_COLOR : GREEN_COLOR, borderRadius: '100%', cursor: 'pointer' }} />
                                                                <p><FormattedMessage defaultMessage={'{count, plural, one {There are 1 hour planned out of the {planned} planned} other {There are {count} hours planned out of the {planned} planned}}'} values={{ count: m.totalizedEventsTotalDuration, planned: m.timeToDo }} /></p>
                                                            </SpaceContent>

                                                            {
                                                                m.type === IMissionRuleType.ONCE && m.totalizedEventsTotalHumans !== 0 && !moment(m.ruleStartDate).isSame(moment(m.ruleEndDate), "day") && !moment(m.ruleEndDate).isSame(moment(date.title), "day") ?
                                                                    <Alert
                                                                        showIcon
                                                                        type='info'
                                                                        message={
                                                                            <>
                                                                                <p><FormattedMessage defaultMessage={'This is a one-time mission. When at least one event is found, control is deactivated for the following days.'} /></p>
                                                                            </>
                                                                        }
                                                                    />
                                                                    : null
                                                            }
                                                        </>
                                                    }
                                                >

                                                    <div style={{ width: '15px', height: '15px', backgroundColor, borderRadius: '100%', cursor: 'pointer' }}></div>
                                                </Popover>
                                            </SpaceContent>
                                        ),
                                        key: ruleKey
                                    }
                                })
                            }
                        })
                    }
                })
            }
        });
        return { treeData, expandedKeys }
    }

    genMissionParsed = (missionsStatus: IMissionStatus[]) => {
        return this.groupBy(missionsStatus, "startDate", "startDate").map(date => ({
            ...date,
            data: this.groupBy(date.items, "customerId", "customerTitle").map(customer => ({
                ...customer,
                data: this.groupBy(customer.items, "mandateId", "mandateTitle").map(mandate => ({
                    ...mandate,
                    data: this.groupBy(mandate.items, "missionId", "missionTitle").map(mission => ({
                        ...mission,
                    })),
                }))
            }))
        }));
    }

    getMissionsStatus = () => {
        this.setState({ loading: true });
        Network.getMissionStatus(this.props.startDate, this.props.endDate).then(
            response => {
                const { filteredMissionsStatus } = this.state;
                const missionsParsed = this.genMissionParsed(response.data)
                const { treeData, expandedKeys } = this.genTreeData(missionsParsed)
                this.setState({ missionsStatus: response.data, missionsParsed, treeData, expandedKeys }, () => {
                    if (filteredMissionsStatus !== undefined) {
                        this.setState({ refreshMissionsStatus: true })
                    }
                })
            },
            () => {
                showNotification("Erreur lors du chargement de l'état des missions", "error");
            }
        ).finally(() => this.setState({ loading: false }));
    }

    clickOnMissionRule = (ruleId: number, date: string, templateId?: number) => {
        this.props.newEvent && this.props.newEvent(ruleId, moment(date), templateId);
    }

    onExpand = (expandedKeys: React.Key[]) => {
        this.setState({ expandedKeys });
    };

    render() {
        const { loading, treeData, expandedKeys, missionsStatus } = this.state;

        return (
            <Can rule={Rules.CustomerManagement.Visit} redirect="/dashboard">
                <Spin spinning={loading}>
                    <MissionFilters
                        loading={loading}
                        missionsStatus={missionsStatus}
                        setMissions={(filteredMissionsStatus?: IMissionStatus[]) => {
                            if (filteredMissionsStatus === undefined) {
                                const missionsParsed = this.genMissionParsed(missionsStatus)
                                const { treeData, expandedKeys } = this.genTreeData(missionsParsed)
                                this.setState({ filteredMissionsStatus: undefined, treeData, expandedKeys, missionsParsed })
                            } else {
                                const missionsParsed = this.genMissionParsed(filteredMissionsStatus)
                                const { treeData, expandedKeys } = this.genTreeData(missionsParsed)
                                this.setState({ filteredMissionsStatus, missionsParsed, treeData, expandedKeys })
                            }
                        }}
                        refresh={this.state.refreshMissionsStatus}
                        endRefresh={() => this.setState({ refreshMissionsStatus: false })}
                    />
                    <Tree
                        className='__mission-control'
                        checkable={false}
                        selectable={false}
                        treeData={treeData}
                        expandedKeys={expandedKeys}
                        onExpand={this.onExpand}
                    />
                    {/* {
                        missionsParsed.map(date => (<>
                            <p>{moment(date.title).format(MOMENT_FORMAT_DISPLAY_DATE)}</p>
                            {date.data.map(customer => (<>
                                <p>{customer.title}</p>
                                {customer.data.map(mandate => (<>
                                    <p>{mandate.title}</p>
                                    {mandate.data.map(mission => (<>
                                        {mission.items.map(m => {
                                            <p>{m.missionTitle}</p>
                                            let backgroundColor = GREEN_COLOR;
                                            if (m.remainingHumanToHave > 0 && m.remainingTimeToDo > 0) {
                                                backgroundColor = RED_COLOR;
                                            } else if (m.remainingHumanToHave > 0 || m.remainingTimeToDo > 0) {
                                                backgroundColor = YELLOW_COLOR;
                                            }
                                            return (
                                                <SpaceContent style={{ justifyContent: 'space-between' }} key={`mission-${m.ruleId}-${m.startDate}}`} onClick={() => this.clickOnMissionRule(m.ruleId, m.startDate, m.templateId)}>
                                                    <p style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{m.missionTitle}</p>
                                                    <Popover
                                                        placement='left'
                                                        mouseEnterDelay={0.3}
                                                        mouseLeaveDelay={0}
                                                        content={
                                                            <>
                                                                <SpaceContent space={10}>
                                                                    <div style={{ width: '10px', height: '10px', backgroundColor: m.remainingHumanToHave > 0 ? RED_COLOR : GREEN_COLOR, borderRadius: '100%', cursor: 'pointer' }} />
                                                                    <p>Il y a {m.humanToHave - m.remainingHumanToHave} personnes planifiées sur les {m.humanToHave} prévues.</p>
                                                                </SpaceContent>
                                                                <SpaceContent space={10}>
                                                                    <div style={{ width: '10px', height: '10px', backgroundColor: m.remainingTimeToDo > 0 ? RED_COLOR : GREEN_COLOR, borderRadius: '100%', cursor: 'pointer' }} />
                                                                    <p>Il y a {m.timeToDo - m.remainingTimeToDo} heures planifiées sur les {m.timeToDo} prévues.</p>
                                                                </SpaceContent>
                                                            </>
                                                        }
                                                    >

                                                        <div style={{ width: '20px', height: '20px', backgroundColor, borderRadius: '100%', cursor: 'pointer' }}></div>
                                                    </Popover>
                                                </SpaceContent>
                                            )
                                        })
                                        }</>))
                                    }</>))
                                }</>))
                            }</>))
                    } */}
                </Spin>
            </Can>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    displayFilters: state.window.displayFilters,
    isSmartphone: state.window.isSmartphone,
    width: state.window.width,
})

const connector = connect(mapStateToProps)

export default connector(MissionControl);