import { CloseOutlined, EditOutlined, FormOutlined, MobileOutlined, PhoneOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Col, Empty, List, Modal, Row } from 'antd';
import { cloneDeep } from 'lodash';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Rules } from '../../../rbacRules';
import '../../../styles/emergencies.css';
import Network from '../../../utils/network';
import { EmergencyContact, MobileAppSettings, MobileAppTheme } from '../../../utils/types/generalTypes';
import { showNotification } from '../../../utils/utils';
import { IntlProps } from '../../app/LanguageProvider';
import CircleButton from '../../common/fields/circleButton';
import DeleteButton from '../../common/fields/deleteButton';
import InputField, { InputFieldOnChangeEvent } from '../../common/fields/inputField';
import Can from '../../common/general/can';
import Card from '../../common/general/card';
import Phone from './components/phone';

interface AppButton {
    [key: string]: string;
    name: string;
    number: string;
}

interface Props extends IntlProps {
    mobileAppSettings: MobileAppSettings;
    theme: MobileAppTheme
    loading: boolean;
}

interface State {
    contacts: EmergencyContact[];
    editContact?: EmergencyContact;
    header: string;
    editHeader?: string;
    button: AppButton;
    editButton?: AppButton;
}

/**
 * Page for the emergency
 */
class Emergencies extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            contacts: [],
            editContact: undefined,
            header: '',
            button: {
                name: '',
                number: '',
            },
            editHeader: undefined,
            editButton: undefined,
        };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        Network.getEmergencies().then(
            (response) => {
                if (response.error) {
                    showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the emergencies' }), "error");
                } else {
                    const { emergencyContacts, emergencyHeaders, emergencyNumbers } = response.data;

                    this.setState({
                        contacts: emergencyContacts,
                        editContact: undefined,
                        header: emergencyHeaders.text,
                        button: {
                            name: emergencyNumbers.name,
                            number: emergencyNumbers.number,
                        }
                    })
                }
            },
            () => showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while loading the emergencies' }), "error"),
        );
    }

    showUpdateContactButton = (contact: EmergencyContact): boolean => contact.name.length > 0 && contact.number.length > 0 && contact.location.length > 0;

    onChangeContactName = (value: string) => {
        const { editContact } = this.state;
        if (editContact) {
            editContact.name = value;
            this.setState({ editContact });
        }
    }

    onChangeContactNumber = (value: string) => {
        const { editContact } = this.state;
        if (editContact) {
            editContact.number = value;
            this.setState({ editContact });
        }
    }

    onChangeContactLocation = (value: string) => {
        const { editContact } = this.state;
        if (editContact) {
            editContact.location = value;
            this.setState({ editContact });
        }
    }

    onStartEditContact = (index?: number) => this.setState({ editContact: index ? cloneDeep(this.state.contacts.find(c => c.id === index)) : { name: '', number: '', location: '' } });

    onCancelUpdateContact = () => this.setState({ editContact: undefined });

    onUpdateContact = () => {
        const { editContact } = this.state;
        const { intl } = this.props;
        if (editContact) {

            (editContact.id ? Network.updateEmergencyContact(editContact.id, editContact) : Network.createEmergencyContact(editContact)).then(
                (response) => {
                    if (response.error) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the emergencies' }), "error");
                    } else {
                        const contacts = cloneDeep(this.state.contacts);
                        const newContact = response.data;
                        const foundContactIndex = contacts.findIndex(c => c.id === newContact.id);
                        if (foundContactIndex >= 0) contacts[foundContactIndex] = newContact;
                        else contacts.push(newContact);
                        this.setState({ contacts, editContact: undefined });
                        if (editContact.id) {
                            showNotification(intl.formatMessage({ defaultMessage: 'The contact has been successfully updated' }), "success");
                        }
                        else {
                            showNotification(intl.formatMessage({ defaultMessage: 'Contact successfully created' }), "success");
                        }
                    }
                },
                () => {
                    if (editContact.id) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the contact' }), "error");
                    }
                    else {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while creating the contact' }), "error");
                    }
                },
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Missing data for the contact' }), "error");
        }
    }

    onDeleteContact = (contactId: number): void => {
        const { intl } = this.props;
        Network.deleteEmergencyContact(contactId).then(
            (response) => {
                if (response.error) {
                    showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the contact' }), "error");
                } else {
                    let contacts = cloneDeep(this.state.contacts);
                    const successIds = response.successIds
                    if (successIds)
                        contacts = contacts.filter(c => !successIds.some(id => id === c.id));
                    this.setState({ contacts, editContact: undefined });
                    showNotification(intl.formatMessage({ defaultMessage: 'Contact successfully deleted' }), "success");
                }
            },
            () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while deleting the contact' }), "warning"),
        );
    }

    onChangeButton = (event: InputFieldOnChangeEvent, key: string): void => {
        const { editButton } = this.state;
        editButton![key] = event.target.value;
        this.setState({ editButton });
    }

    updateHeader = (): void => {
        const { intl } = this.props;
        if (this.state.editHeader) {
            Network.updateEmergencyHeaderText(this.state.editHeader).then(
                (response) => {
                    if (response.error) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the emergencies' }), "error");
                    } else {
                        this.setState({ editHeader: undefined, header: response.data.text });
                    }
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the header' }), "warning"),
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Missing data for the header' }), "error");
        }
    }

    updateButton = (): void => {
        const { intl } = this.props;
        const { editButton } = this.state;
        if (editButton) {
            Network.updateEmergencyButton(editButton!.name, editButton!.number).then(
                (response) => {
                    if (response.error) {
                        showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while loading the emergencies' }), "error");
                    } else {
                        this.setState({ button: { name: response.data.name, number: response.data.number }, editButton: undefined });
                    }
                },
                () => showNotification(intl.formatMessage({ defaultMessage: 'An error occurred while updating the header' }), "warning"),
            );
        } else {
            showNotification(this.props.intl.formatMessage({ defaultMessage: 'Missing data, please complete all fields.' }), "error");
        }
    }

    renderListItem = (item: EmergencyContact, index: number): React.ReactNode => {
        const { intl } = this.props;
        return (
            <List.Item
                key={`renderListItem-contact-${item.id}-${index}`}
                actions={[
                    <CircleButton
                        key={`emergencies-render-list-item-${item.id}-modify`} icon={<EditOutlined />} title={intl.formatMessage({ defaultMessage: 'Edit' })} onClick={() => this.onStartEditContact(item.id)} />,
                    <DeleteButton
                        key={`emergencies-render-list-item-${item.id}-delete`}
                        text={<FormattedMessage defaultMessage={'Are you sure you want to delete this contact?'} />}
                        okText={intl.formatMessage({ defaultMessage: 'Yes' })}
                        cancelText={intl.formatMessage({ defaultMessage: 'No' })}
                        onConfirm={() => item.id !== undefined ? this.onDeleteContact(item.id) : null}
                        placement="left" />,
                ]}
            >
                <List.Item.Meta
                    title={
                        <div className="emergency-list-item">
                            <p>{item.name}</p>
                            <p>{item.number}</p>
                            <p>{item.location}</p>
                        </div>
                    }
                />
            </List.Item>
        );
    }

    render() {
        const { intl } = this.props;
        const { contacts, editContact, header, button, editHeader, editButton } = this.state;
        const phoneData: {
            headerText?: string,
            buttonText?: string,
            contacts: {
                name?: string,
                location?: string
            }[]
        } = {
            headerText: header,
            buttonText: button.name,
            contacts: contacts
        }
        return (
            <Can rule={Rules.Emergencies.Visit} redirect="/dashboard">
                <div>
                    <Row gutter={[20, 20]}>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Row gutter={[20, 20]} style={{ minHeight: '100%' }}>
                                <Col xs={{ span: 24 }}>
                                    <Card title={<FormattedMessage defaultMessage={'Emergency settings'} />} icon={<SettingOutlined />}>
                                        <div>
                                            <p className="emergency-mobile-section-title"><FormattedMessage defaultMessage={'Header'} /></p>
                                            <div className="flex-row">
                                                <div className="emergency-mobile-section">
                                                    <span className="emergency-mobile-section-line">
                                                        {
                                                            editHeader !== undefined ?
                                                                <InputField
                                                                    className="emergency-mobile-input"
                                                                    type="textarea"
                                                                    value={editHeader}
                                                                    placeholder={intl.formatMessage({ defaultMessage: 'Text' })}
                                                                    autoSize={{ minRows: 5 }}
                                                                    onChange={(e: InputFieldOnChangeEvent) => this.setState({ editHeader: e.target.value })} />
                                                                :
                                                                header ? <pre style={{ textAlign: 'left', fontFamily: 'inherit' }}>{header}</pre> : <p><FormattedMessage defaultMessage={'No text'} /></p>
                                                        }
                                                    </span>
                                                </div>
                                                {
                                                    editHeader !== undefined ?
                                                        <span>
                                                            {editHeader.length > 0 && <CircleButton small icon={<FormOutlined />} title={intl.formatMessage({ defaultMessage: 'Save' })} style={{ marginRight: '10px' }} onClick={this.updateHeader} />}
                                                            <CircleButton small icon={<CloseOutlined />} title={intl.formatMessage({ defaultMessage: 'Cancel' })} onClick={() => this.setState({ editHeader: undefined })} />
                                                        </span>
                                                        :
                                                        <span>
                                                            <CircleButton small icon={<EditOutlined />} title={intl.formatMessage({ defaultMessage: 'Edit' })} onClick={() => this.setState({ editHeader: header ? header : '' })} />
                                                        </span>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <p className="emergency-mobile-section-title"><FormattedMessage defaultMessage={'Button'} /></p>
                                            <div className="flex-row">
                                                <div className="emergency-mobile-section">
                                                    <span className="emergency-mobile-section-line">
                                                        <p><FormattedMessage defaultMessage={'Title'} />{':'}</p>
                                                        {
                                                            editButton ?
                                                                <InputField
                                                                    className="emergency-mobile-input"
                                                                    value={editButton.name}
                                                                    placeholder={intl.formatMessage({ defaultMessage: 'Title' })}
                                                                    onChange={(e: InputFieldOnChangeEvent) => this.onChangeButton(e, 'name')} />
                                                                :
                                                                button.name ? <p>{button.name}</p> : <p><FormattedMessage defaultMessage={'No text'} /></p>
                                                        }
                                                    </span>
                                                    <span className="emergency-mobile-section-line">
                                                        <p><FormattedMessage defaultMessage={'Phone'} />{':'}</p>
                                                        {
                                                            editButton ?
                                                                <InputField
                                                                    showCount
                                                                    maxLength={20}
                                                                    className="emergency-mobile-input"
                                                                    value={editButton.number}
                                                                    placeholder={intl.formatMessage({ defaultMessage: 'Phone' })}
                                                                    onChange={(e: InputFieldOnChangeEvent) => this.onChangeButton(e, 'number')} />
                                                                :
                                                                <p>{button.number ? button.number : <FormattedMessage defaultMessage={'No phone number'} />}</p>
                                                        }
                                                    </span>
                                                </div>
                                                <div>
                                                    {
                                                        editButton ?
                                                            <span>
                                                                {editButton.name && editButton.number && <CircleButton small icon={<FormOutlined />} title={intl.formatMessage({ defaultMessage: 'Save' })} style={{ marginRight: '10px' }} onClick={this.updateButton} />}
                                                                <CircleButton small icon={<CloseOutlined />} title={intl.formatMessage({ defaultMessage: 'Cancel' })} onClick={() => this.setState({ editButton: undefined })} />
                                                            </span>
                                                            :
                                                            <span>
                                                                <CircleButton small icon={<EditOutlined />} title={intl.formatMessage({ defaultMessage: 'Edit' })} onClick={() => this.setState({ editButton: { name: button.name ? button.name : '', number: button.number ? button.number : '' } })} />
                                                            </span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={{ span: 24 }}>
                                    <Card
                                        title={intl.formatMessage({ defaultMessage: 'Contacts' })}
                                        icon={<PhoneOutlined />}
                                        headerElements={
                                            [
                                                <CircleButton
                                                    icon={<PlusOutlined />}
                                                    title={intl.formatMessage({ defaultMessage: 'Add a contact' })}
                                                    key="emergency-add-button"
                                                    onClick={() => this.onStartEditContact()} />
                                            ]
                                        }
                                    >
                                        <List
                                            dataSource={contacts}
                                            renderItem={this.renderListItem}
                                            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<FormattedMessage defaultMessage={'No contact'} />} /> }}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Card title={<FormattedMessage defaultMessage={'Overview'} />} icon={<MobileOutlined />} style={{ height: 'fit-content', minHeight: 'fit-content' }}>
                                <Phone
                                    data={phoneData}
                                    theme={this.props.theme}
                                    mobileAppSettings={this.props.mobileAppSettings}
                                    pageTitle={intl.formatMessage({ defaultMessage: 'Emergencies' })}
                                    page={'emergencies'}
                                />
                            </Card>
                        </Col>
                    </Row>

                </div>
                {
                    editContact ?
                        <Modal
                            visible={editContact !== undefined}
                            title={
                                editContact.id ?
                                    <FormattedMessage defaultMessage={'Edit contact'} />
                                    :
                                    <FormattedMessage defaultMessage={'Add a contact'} />
                            }
                            onOk={this.onUpdateContact}
                            onCancel={this.onCancelUpdateContact}>
                            <InputField
                                className="emergency-contact-input"
                                value={editContact.name}
                                placeholder={intl.formatMessage({ defaultMessage: 'Contact name' })}
                                onPressEnter={this.onUpdateContact}
                                onChange={e => this.onChangeContactName(e.target.value)} />
                            <InputField
                                className="emergency-contact-input"
                                value={editContact.number}
                                placeholder={intl.formatMessage({ defaultMessage: 'Phone number' })}
                                onPressEnter={this.onUpdateContact}
                                onChange={e => this.onChangeContactNumber(e.target.value)} />
                            <InputField
                                className="emergency-contact-input"
                                value={editContact.location}
                                placeholder={intl.formatMessage({ defaultMessage: 'Location' })}
                                onPressEnter={this.onUpdateContact}
                                onChange={e => this.onChangeContactLocation(e.target.value)} />
                        </Modal>
                        : null
                }
            </Can>
        );
    }
}

export default injectIntl(Emergencies);