import { Button, Drawer, Spin } from 'antd';
import { Moment } from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import '../../../styles/ccnt.css';
import { User } from '../../../utils/types/generalTypes';
import { CctSecurityEventsByContractByMonth } from '../../../utils/types/reportTypes';
import { ApplicationState } from '../../../utils/types/storeTypes';
import ReportContent from './reportContent';

interface IProps {
    isVisible: boolean;
    isLoading: boolean;
    cctSecurity?: CctSecurityEventsByContractByMonth[];
    close: () => void;
    user?: User;
    startMonth: Moment;
    endMonth: Moment;

    isSmartphone: boolean;
}

type Props = IProps;

interface State {
    print: boolean;
}

class DrawerCctSecurity extends React.Component<Props, State> {
    componentRef: any = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            print: false
        };
    }



    close = () => {
        this.props.close();
    }



    printPDF = () => {
        this.setState({ print: true })
    };



    render() {
        const { isLoading } = this.props;

        return (
            <>
                <Drawer
                    destroyOnClose={true}
                    width={this.props.isSmartphone ? '100% ' : '90%'}
                    title={(this.props.user === undefined ? <FormattedMessage defaultMessage={'An error occurred'} /> : <p>{`${this.props.user.last_name} ${this.props.user.first_name} - ${this.props.endMonth?.format('MMMM YYYY')}`}</p>)}
                    placement="right"
                    onClose={() => this.close()}
                    visible={this.props.isVisible}
                    className="__drawer"
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            {
                                <>
                                    <Button onClick={() => this.close()} style={{ marginRight: 8 }}>
                                        <FormattedMessage defaultMessage={'Close'} />
                                    </Button>
                                    <Button type="primary" loading={this.state.print} onClick={this.printPDF} ><FormattedMessage defaultMessage={'Download'} /></Button>
                                </>
                            }
                        </div>
                    }
                >
                    {
                        isLoading || !this.props.cctSecurity ?
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', borderRadius: 'var(--border-radius)', border: '1px solid rgb(177, 177, 177)' }}>
                                <Spin size='large' />
                            </div>
                            :
                            <div className='interactivCcnt'>
                                <ReportContent cctSecurity={this.props.cctSecurity} print={this.state.print} togglePrint={() => this.setState({ print: !this.state.print })} monthReport={this.props.endMonth} user={this.props.user} ref={el => (this.componentRef = el)} />
                            </div>
                    }
                </Drawer>
            </>
        );

    }
}

const mapStateToProps = (state: ApplicationState) => ({
    isSmartphone: state.window.isSmartphone,
});

export default connect(mapStateToProps)(DrawerCctSecurity);