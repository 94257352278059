import { BellOutlined, EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, DatePicker, Divider, message, Radio, Row } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Rules } from '../../rbacRules';
import '../../styles/newsfeed.css';
import { MOMENT_SHORT_TIME_FORMAT } from '../../utils/constants';
import getFormat from '../../utils/Lang';
import Network from '../../utils/network';
import { RouterProps } from '../../utils/types/generalTypes';
import { CreateNewsBody } from '../../utils/types/networkTypes';
import { ApplicationState } from '../../utils/types/storeTypes';
import { showNotification } from '../../utils/utils';
import { IntlProps } from '../app/LanguageProvider';
import FileUploaderMultiple from '../common/fields/fileUploaderMultiple';
import ImageUploaderMultiple from '../common/fields/imageUploaderMultiple';
import InputField from '../common/fields/inputField';
import Can from '../common/general/can';
import Card from '../common/general/card';
import Container from '../common/navigations/container';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

type ReduxProx = ConnectedProps<typeof connector>

interface IProps {
}

type Props = IProps & RouterProps & ReduxProx & IntlProps;

interface State {
    destinator: "S" | "C" | "A" | undefined;
    title: (string | undefined)[];
    content: (string | undefined)[];
    images?: (File | string)[];
    notification: "P" | "E" | undefined;
    startDate: string | undefined;
    withComment: boolean;
    isNewsflash: boolean;
    withLike: boolean;
    likeName: string | undefined;
    files?: (File | string)[];
    loading: boolean
}

/**
 * Page to create a News
 */
class CreateNewsfeed extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            destinator: "S",
            title: [undefined, undefined, undefined, undefined],
            content: [undefined, undefined, undefined, undefined],
            notification: undefined,
            startDate: undefined,
            withComment: true,
            isNewsflash: false,
            withLike: true,
            likeName: undefined,
            loading: false
        }
    }

    /**
     * Decide weither or not to show the translation contents fields
     */
    showTranslationContent = () => this.state.destinator === "C" || this.state.destinator === "A";

    /**
     * Decide weither or not to show the create button
     */
    showButton = () => {
        const { destinator, title, content } = this.state;
        let bool: boolean = destinator !== undefined && title !== undefined && title.length > 0;
        if (this.showTranslationContent()) {
            bool = content[0] !== undefined && content[0] !== "" && content[1] !== undefined && content[1] !== "" && content[2] !== undefined && content[2] !== "" && title[0] !== undefined && title[0] !== "" && title[1] !== undefined && title[1] !== "" && title[2] !== undefined && title[2] !== "";
        } else {
            bool = bool && content[0] !== undefined && content[0] !== "" && title[0] !== undefined && title[0] !== "";
        }
        return bool;
    }

    /**
     * Called when a title InputField changed
     * @param event the triggered event
     * @param index the index of the title that changed
     */
    changeTitle = (event: any, index: number) => {
        const { title } = this.state;
        title[index] = event.target.value;
        this.setState({ title });
    }

    /**
     * Called when a content InputField changed
     * @param event the triggered event
     * @param index the index of the content that changed
     */
    changeContent = (event: any, index: number) => {
        if (event.target) {
            const { content } = this.state;
            content[index] = event.target.value;
            this.setState({ content });
        } else {
            const { content } = this.state;
            content[index] = event;
            this.setState({ content });
        }
    }

    /**
     * Handle the creation of the news
     */
    handleOnSubmit = () => {
        const { files, destinator, title, content, images, notification, startDate, withComment, withLike, likeName, isNewsflash } = this.state;
        this.setState({ loading: true })

        if (!this.showTranslationContent()) {
            if (title[0] === undefined || title[0] === '') {
                message.error(<FormattedMessage defaultMessage={'Please fill in the title field'} />)
                return
            }
            if (content[0] === undefined || content[0] === '') {
                message.error(<FormattedMessage defaultMessage={'Please fill in the content field'} />)
                return
            }
        }
        //create the request body
        const body: CreateNewsBody = {
            is_instructor: destinator !== undefined && (destinator === "S" || destinator === "A"),
            is_client: destinator !== undefined && (destinator === "C" || destinator === "A"),
            title: title[1] ? title[1] : title[0] as string,
            title_fr: title[1] ? title[0] : undefined,
            title_de: title[2],
            title_it: title[3],
            text: content[1] ? content[1] : content[0] as string,
            text_fr: content[1] ? content[0] : undefined,
            text_de: content[2],
            text_it: content[3],
            image: images && images.length > 0 ? images[0] : undefined,
            images: images,
            files: files,
            email_notification: notification && notification === "E",
            push_notification: notification && notification === "P",
            start_date: startDate,
            comment_enabled: withComment,
            is_newsflash: isNewsflash,
            like_enabled: withLike,
            like_name: likeName && likeName.length > 0 ? likeName : undefined,
        };

        //create the news
        Network.createNews(body).then(
            () => this.props.history.push({ pathname: `/${this.props.match.params.lang}/newsfeed/list`, state: { successMessage: this.props.intl.formatMessage({ defaultMessage: 'The News has been successfully created' }) } }),
            () => {
                this.setState({ loading: false })
                showNotification(this.props.intl.formatMessage({ defaultMessage: 'An error occurred while creating the news' }), "warning")
            }
        );
    }

    disabledDate = (current: Moment): boolean => {
        // Can not select days before today and today
        if (current.isSameOrAfter(moment(), 'minute')) return false
        else return true
    }

    render() {
        const { files, destinator, title, content, notification, images, withComment, withLike, likeName, isNewsflash } = this.state;
        const { intl } = this.props;

        return (
            <Container breadcrumb={[{ title: intl.formatMessage({ defaultMessage: 'News' }), link: "/newsfeed/list" }, { title: "Ajouter", link: "/newsfeed/create" }]}>
                <Row gutter={[20, 20]}>
                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                        <Card className="newsfeed-left" icon={<FormOutlined />} title={intl.formatMessage({ defaultMessage: 'Write a new news item' })}>
                            {
                                this.props.ext &&
                                <Radio.Group
                                    className="newsfeed-input"
                                    onChange={(e) => this.setState({ destinator: e.target.value })}
                                    value={destinator}>
                                    <Radio value="S"><FormattedMessage defaultMessage={'For the staff'} /></Radio>
                                    <Can rule={Rules.Newsfeed.Filter}>
                                        <Radio value="C"><FormattedMessage defaultMessage={'For customers'} /></Radio>
                                        <Radio value="A"><FormattedMessage defaultMessage={'For all'} /></Radio>
                                    </Can>
                                </Radio.Group>
                            }
                            <InputField
                                className="newsfeed-input"
                                maxLength={300}
                                showCount
                                onChange={(e) => this.changeTitle(e, 0)}
                                value={title[0]}
                                placeholder={intl.formatMessage({ defaultMessage: 'Title {language}' }, { language: this.showTranslationContent() ? '(Fr)*' : '' })} />
                            {
                                destinator &&
                                // <ReactQuill theme="snow" value={content[0]} onChange={(e) => this.changeContent(e, 0)} />
                                <InputField
                                    className="newsfeed-input"
                                    style={{ marginTop: '10px' }}
                                    type="textarea"
                                    maxLength={2500}
                                    showCount
                                    autoSize={{ minRows: 3 }}
                                    onChange={(e) => this.changeContent(e, 0)}
                                    value={content[0]}
                                    placeholder={intl.formatMessage({ defaultMessage: 'Content {language}' }, { language: this.showTranslationContent() ? '(Fr)*' : '' })} />
                            }
                            {
                                this.showTranslationContent() &&
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        onChange={(e) => this.changeTitle(e, 1)}
                                        value={title[1]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title (En)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        type="textarea"
                                        autoSize={{ minRows: 3 }}
                                        maxLength={2500}
                                        showCount
                                        onChange={(e) => this.changeContent(e, 1)}
                                        value={content[1]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Content (En)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        maxLength={100}
                                        showCount
                                        onChange={(e) => this.changeTitle(e, 2)}
                                        value={title[2]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title (De)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        type="textarea"
                                        autoSize={{ minRows: 3 }}
                                        maxLength={2500}
                                        showCount
                                        onChange={(e) => this.changeContent(e, 2)}
                                        value={content[2]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Content (De)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        maxLength={100}
                                        showCount
                                        onChange={(e) => this.changeTitle(e, 3)}
                                        value={title[3]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Title (It)*' })} />
                                    <InputField
                                        className="newsfeed-input expand-appear-animation"
                                        type="textarea"
                                        autoSize={{ minRows: 3 }}
                                        maxLength={2500}
                                        showCount
                                        onChange={(e) => this.changeContent(e, 3)}
                                        value={content[3]}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Content (It)*' })} />
                                </div>
                            }
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 4, justifyContent: 'space-between' }}>
                                <ImageUploaderMultiple
                                    deleteTextMaxWidth={250}
                                    //className="newsfeed-image-uploader"
                                    files={images}
                                    max={10}
                                    editText={intl.formatMessage({ defaultMessage: 'Add more images' })}
                                    addText={intl.formatMessage({ defaultMessage: 'Add one or more images' })}
                                    containerStyle={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 18, alignItems: 'center' }}
                                    onUploaded={(images: File[] | undefined) => this.setState({ images })}
                                />
                                <Divider type={'vertical'} style={{ height: 'auto' }} />
                                <FileUploaderMultiple
                                    deleteTextMaxWidth={250}
                                    accept=".pdf"
                                    max={5}
                                    editText={intl.formatMessage({ defaultMessage: 'Add more PDF documents' })}
                                    addText={intl.formatMessage({ defaultMessage: 'Add one or more PDF documents' })}
                                    //className="newsfeed-image-uploader"
                                    containerStyle={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 18, alignItems: 'center' }}
                                    files={files ? files : []}
                                    onUploaded={(files: File[] | undefined) => this.setState({ files })}
                                />

                            </div>
                        </Card>
                    </Col>

                    <Col xs={{ span: 24 }} xxl={{ span: 12 }}>
                        <Row gutter={[20, 20]}>
                            <Col xs={{ span: 24 }} >
                                <Card icon={<BellOutlined />} title={<FormattedMessage defaultMessage={'Notifications'} />}>
                                    <Radio.Group
                                        className="newsfeed-input"
                                        onChange={(e) => this.setState({ notification: e.target.value })}
                                        value={notification}>
                                        <Radio value="P"><FormattedMessage defaultMessage={'Push notification'} /></Radio>
                                        <Radio value="E"><FormattedMessage defaultMessage={'Email'} /></Radio>
                                    </Radio.Group>
                                </Card>
                            </Col>
                            <Col xs={{ span: 24 }} >
                                <Card className="newsfeed-options" icon={<EllipsisOutlined />} title={<FormattedMessage defaultMessage={'Settings'} />}>
                                    <DatePicker
                                        className="newsfeed-input"
                                        style={{ width: '100%' }}
                                        placeholder={intl.formatMessage({ defaultMessage: 'Date news sent' })}
                                        showTime={{ format: MOMENT_SHORT_TIME_FORMAT }}
                                        disabledDate={this.disabledDate}
                                        format={getFormat('DATE_AND_TIME_SHORT')}
                                        onOk={(d) => this.setState({ startDate: d?.format("YYYY-MM-DDTHH:mm") })} />
                                    <Checkbox
                                        className="newsfeed-input"
                                        checked={withComment}
                                        onChange={(e) => this.setState({ withComment: e.target.checked })}
                                    >
                                        <FormattedMessage defaultMessage={'Allow comments'} />
                                    </Checkbox>
                                    <br />
                                    {
                                        (this.state.destinator === "C") &&
                                        <Checkbox
                                            className="newsfeed-input"
                                            checked={isNewsflash}
                                            onChange={(e) => this.setState({ isNewsflash: e.target.checked })}
                                        >
                                            <FormattedMessage defaultMessage={'Newsflash'} />
                                        </Checkbox>
                                    }
                                    {(this.state.destinator === "C") && <br />}
                                    <Checkbox
                                        className="newsfeed-input"
                                        checked={withLike}
                                        onChange={(e) => this.setState({ withLike: e.target.checked })}
                                    >
                                        <FormattedMessage defaultMessage={'Allow likes'} />
                                    </Checkbox>
                                    <br />
                                    {
                                        withLike ?
                                            <InputField
                                                showCount
                                                maxLength={20}
                                                className="newsfeed-input"
                                                placeholder={intl.formatMessage({ defaultMessage: 'Change "Like" text' })}
                                                value={likeName}
                                                onChange={(e) => this.setState({ likeName: e.target.value })} />
                                            : null
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{ span: 24 }} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            disabled={!this.showButton()}
                            type="primary"
                            className="newsfeed-input newsfeed-submit"
                            onClick={this.handleOnSubmit}
                            loading={this.state.loading}
                        >
                            <FormattedMessage defaultMessage={'Send'} />
                        </Button>
                    </Col>
                </Row>
            </Container >
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    ext: state.user.currentUser?.company_detail?.ext,
    currentUser: state.user.currentUser,
});

const connector = connect(mapStateToProps)
export default connector(injectIntl(CreateNewsfeed));